import history from '../../../utilities/history'
import { hideModal } from '../../actions'
import * as report from '../../../apis/report'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'
import { QuestionType } from '../../../utilities/reportBuilder/questionTypeEnum'
import { ComparisonType } from '../../../utilities/reportBuilder/comparisonTypeEnum'
import { getDueSortOrder, getXAxisLabel, populationSetup } from '../../../utilities/chartUtilities'
import { CHART_TYPES } from '../../../utilities/reportBuilder/chartTypeEnum'

const setReportConfiguration = (dispatch, response) => {
	dispatch({
		type: FETCH_CONFIGURATION_SUCCESS,
		data: {
			configuration: response.configuration,
			circles: response.circles,
			scoringGroups: response.scoringGroups
		}
	})
}

const SET_CIRCLE_REQUESTED = 'SET_CIRCLE_REQUESTED'
const SET_CIRCLE_SUCCESS = 'SET_CIRCLE_SUCCESS'
const SET_CIRCLE_FAILED = 'SET_CIRCLE_FAILED'

export const setCircle = (id, circleId, data) => (dispatch, getState) => {
	dispatch({ type: FETCH_REPORT_CIRCLES_REQUESTED, data: {} })

	report.setReportCircle(id, circleId, data).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(fetchReportConfiguration(id))
			dispatch(fetchReportData(id))
			return dispatch(hideModal())
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.failedToFetchYAxisSelections', isSuccess: false }
			})
		}
	})
}

const FETCH_REPORT_CIRCLES_REQUESTED = 'FETCH_REPORT_CIRCLES_REQUESTED'
const FETCH_REPORT_CIRCLES_SUCCESS = 'FETCH_REPORT_CIRCLES_SUCCESS'
const FETCH_REPORT_CIRCLES_FAILED = 'FETCH_REPORT_CIRCLES_FAILED'

export const fetchReportCircles = () => (dispatch, getState) => {
	dispatch({ type: FETCH_REPORT_CIRCLES_REQUESTED, data: {} })

	report.fetchReportCircles().then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: FETCH_REPORT_CIRCLES_SUCCESS, data: response.circles })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.failedToFetchYAxisSelections', isSuccess: false }
			})
		}
	})
}

const FETCH_YAXIS_REQUESTED = 'FETCH_YAXIS_REQUESTED'
const FETCH_YAXIS_SUCCESS = 'FETCH_YAXIS_SUCCESS'
const FETCH_YAXIS_FAILED = 'FETCH_YAXIS_FAILED'

export const fetchYAxisAutoComplete = (circleId) => (dispatch, getState) => {
	dispatch({ type: FETCH_YAXIS_REQUESTED, data: {} })
	let state = getState()
	if (state) {
		let configuration = currentConfigurationSelector(state)
		if (configuration && configuration.circleId > 0) {
			report.fetchYAxis(configuration.circleId).then((response) => {
				if (response && response.isSuccessful) {
					dispatch({ type: FETCH_YAXIS_SUCCESS, data: response.groups })
				} else {
					return dispatch({
						type: 'SET_SNACKBAR_MESSAGE',
						data: { message: 'app.report.failedToFetchYAxisSelections', isSuccess: false }
					})
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.cohort.failedToFetchYAxisSelections', isSuccess: false }
			})
		}
	}
}

const FETCH_YAXIS_FOR_CIRCLE_REQUESTED = 'FETCH_YAXIS_FOR_CIRCLE_REQUESTED'
const FETCH_YAXIS_FOR_CIRCLE_SUCCESS = 'FETCH_YAXIS_FOR_CIRCLE_SUCCESS'
const FETCH_YAXIS_FOR_CIRCLE_FAILED = 'FETCH_YAXIS_FOR_CIRCLE_FAILED'

export const fetchYAxisForCircleAutoComplete = (circleId) => (dispatch, getState) => {
	dispatch({ type: FETCH_YAXIS_FOR_CIRCLE_REQUESTED, data: {} })

	report.fetchYAxis(circleId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: FETCH_YAXIS_FOR_CIRCLE_SUCCESS, data: response.groups })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.failedToFetchYAxisSelections', isSuccess: false }
			})
		}
	})	
}

const CLEAR_YAXIS_FOR_CIRCLE_REQUESTED = 'CLEAR_YAXIS_FOR_CIRCLE_REQUESTED'

export const clearYAxisForCircleAutoComplete = (circleId) => (dispatch, getState) => {
	dispatch({ type: CLEAR_YAXIS_FOR_CIRCLE_REQUESTED, data: {} })
}

const SET_YAXIS_REQUESTED = 'SET_YAXIS_REQUESTED'
const SET_YAXIS_SUCCESS = 'SET_YAXIS_SUCCESS'
const SET_YAXIS_FAILED = 'SET_YAXIS_FAILED'

export const setYAxis = (data) => (dispatch, getState) => {
	report.setReportScoringGroupId(data).then((response) => {
		if (response && response.isSuccessful) {
			setReportConfiguration(dispatch, response)
			dispatch(fetchReportData(data.id))
			return dispatch(hideModal())
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.failedToFetchYAxisSelections', isSuccess: false }
			})
		}
	})
}

const FETCH_COHORT_AUTOCOMPLETE_REQUESTED = 'FETCH_COHORT_AUTOCOMPLETE_REQUESTED'
const FETCH_COHORT_AUTOCOMPLETE_SUCCESS = 'FETCH_COHORT_AUTOCOMPLETE_SUCCESS'
const FETCH_COHORT_AUTOCOMPLETE_FAILED = 'FETCH_COHORT_AUTOCOMPLETE_FAILED'

export const fetchCohortAutoComplete = (searchPhrase) => (dispatch, getState) => {
	dispatch({ type: FETCH_COHORT_AUTOCOMPLETE_REQUESTED, data: {} })
	let state = getState()
	if (state) {
		let configuration = currentConfigurationSelector(state)

		// if (configuration && configuration.circleId > 0) {
		if (configuration && configuration.circleId > 0) {
			// configuration.circleId
			return report.fetchCohortAutoComplete(configuration.circleId, searchPhrase).then((response) => {
				if (response && response.isSuccessful) {
					if (response.questions) {
						dispatch({ type: FETCH_COHORT_AUTOCOMPLETE_SUCCESS, data: response.questions })
					} else {
						dispatch({ type: FETCH_COHORT_AUTOCOMPLETE_SUCCESS, data: [] })
					}
				} else {
					return dispatch({
						type: 'SET_SNACKBAR_MESSAGE',
						data: { message: 'app.cohort.fetchListFailed', isSuccess: false }
					})
				}
			})
		}
		return dispatch({
			type: 'SET_SNACKBAR_MESSAGE',
			data: { message: 'app.cohort.fetchListFailedMissingCircleId', isSuccess: false }
		})
	}
}

const FETCH_QUESTION_DEFAULTS_REQUESTED = 'FETCH_QUESTION_DEFAULTS_REQUESTED'
const FETCH_QUESTION_DEFAULTS_SUCCESS = 'FETCH_QUESTION_DEFAULTS_SUCCESS'
const FETCH_QUESTION_DEFAULTS_FAILED = 'FETCH_QUESTION_DEFAULTS_FAILED'

export const fetchQuestionDefault = (reportId, id, typeId) => (dispatch, getState) => {
	dispatch({ type: FETCH_QUESTION_DEFAULTS_REQUESTED, data: null })

	return report.fetchQuestionDefault(reportId, id, typeId).then((response) => {
		if (response && response.isSuccessful) {
			if (response.id) {
				dispatch({
					type: FETCH_QUESTION_DEFAULTS_SUCCESS,
					data: {
						id: response.id,
						type: typeId,
						title: response.title,
						answers: response.answers,
						isScoringGroup: false
					}
				})
			} else {
				dispatch({ type: FETCH_QUESTION_DEFAULTS_SUCCESS, data: [] })
			}
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.cohort.fetchListFailed', isSuccess: false }
			})
		}
	})
}

const FETCH_QUESTION_SURVEYS_REQUESTED = 'FETCH_QUESTION_SURVEYS_REQUESTED'
const FETCH_QUESTION_SURVEYS_SUCCESS = 'FETCH_QUESTION_SURVEYS_SUCCESS'
const FETCH_QUESTION_SURVEYS_FAILED = 'FETCH_QUESTION_SURVEYS_FAILED'

export const fetchQuestionSurveys = (circleId, id, questionType) => (dispatch, getState) => {
	dispatch({ type: FETCH_QUESTION_SURVEYS_REQUESTED, data: null })

	if (!!questionType) {
		return report.fetchQuestionSurveys(circleId, id, questionType).then((response) => {
			if (response && response.isSuccessful) {
				return dispatch({
					type: FETCH_QUESTION_SURVEYS_SUCCESS,
					data: response.surveys
				})
			} else {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.cohort.fetchListFailed', isSuccess: false }
				})
				return dispatch({
					type: FETCH_QUESTION_SURVEYS_FAILED,
					data: null
				})
			}
		})
	}
	else {
		return report.fetchScoringGroupSurveys(circleId, id).then((response) => {
			if (response && response.isSuccessful) {
				return dispatch({
					type: FETCH_QUESTION_SURVEYS_SUCCESS,
					data: response.surveys
				})
			} else {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.cohort.fetchListFailed', isSuccess: false }
				})
				return dispatch({
					type: FETCH_QUESTION_SURVEYS_FAILED,
					data: null
				})
			}
		})
	}
}

const SET_COHORT_SCORING_GROUP_REQUESTED = 'SET_COHORT_SCORING_GROUP_REQUESTED'

export const setCohortScoringGroup = (id, title) => (dispatch, getState) => {
	dispatch({
		type: SET_COHORT_SCORING_GROUP_REQUESTED,
		data: {
			id: id,
			type: null,
			title: title,
			answers: null,
			isScoringGroup: true
		}
	})
}

const UPDATE_COHORT_CASE_REQUESTED = 'UPDATE_COHORT_CASE_REQUESTED'
const UPDATE_COHORT_CASE_SUCCESS = 'UPDATE_COHORT_CASE_SUCCESS'
const UPDATE_COHORT_CASE_FAILED = 'UPDATE_COHORT_CASE_FAILED'

export const updateCohortCase = (reportId, cohortId, caseId) => (dispatch, getState) => {
	dispatch({ type: UPDATE_COHORT_CASE_REQUESTED, data: null })

	return report.updateCohortCase(reportId, cohortId, caseId).then((response) => {
		if (response && response.isSuccessful) {
				dispatch(setAllowUpdateCohortCase(false))
				dispatch(fetchReportData(reportId))
				dispatch(fetchReportConfiguration(reportId))
				dispatch({ type: UPDATE_COHORT_CASE_SUCCESS, data: response })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.cohort.updateCaseFailed', isSuccess: false }
			})
		}
	})
}

const FETCH_COHORT_CASE_REQUESTED = 'FETCH_COHORT_CASE_REQUESTED'
const FETCH_COHORT_CASE_SUCCESS = 'FETCH_COHORT_CASE_SUCCESS'
const FETCH_COHORT_CASE_FAILED = 'FETCH_COHORT_CASE_FAILED'

export const fetchCohortCase = (reportId, cohortId) => (dispatch, getState) => {
	dispatch({ type: FETCH_COHORT_CASE_REQUESTED, data: null })

	return report.fetchCohortCase(reportId, cohortId).then((response) => {
		if (response && response.isSuccessful) {
				dispatch({ type: SET_COHORT_CASE_REQUESTED, data: response.cases ? response.cases[0] : null })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.cohort.fetchListFailed', isSuccess: false }
			})
		}
	})
}

const SET_COHORT_CASE_REQUESTED = 'SET_COHORT_CASE_REQUESTED'

export const setCohortCase = (caseValue) => (dispatch, getState) => {
	dispatch({ type: SET_COHORT_CASE_REQUESTED, data: caseValue })
}

export const clearCohortCase = () => (dispatch, getState) => {
	dispatch({ type: SET_COHORT_CASE_REQUESTED, data: null })
}

const SET_ALLOW_UPDATE_COHORT_CASE_REQUESTED = 'SET_ALLOW_UPDATE_COHORT_CASE_REQUESTED'

export const setAllowUpdateCohortCase = (value) => (dispatch, getState) => {
	dispatch({ type: SET_ALLOW_UPDATE_COHORT_CASE_REQUESTED, data: value })
}

const CLEAR_YAXIS_AUTOCOMPLETE_REQUESTED = 'CLEAR_YAXIS_AUTOCOMPLETE_REQUESTED'
const CLEAR_YAXIS_AUTOCOMPLETE_SUCCESS = 'CLEAR_YAXIS_AUTOCOMPLETE_SUCCESS'
const CLEAR_YAXIS_AUTOCOMPLETE_FAILED = 'CLEAR_YAXIS_AUTOCOMPLETE_FAILED'

export const clearYAxisAutoComplete = () => (dispatch) => {
	return dispatch({ type: CLEAR_YAXIS_AUTOCOMPLETE_REQUESTED, data: {} })
}

const CLEAR_COHORT_AUTOCOMPLETE_REQUESTED = 'CLEAR_COHORT_AUTOCOMPLETE_REQUESTED'
const CLEAR_COHORT_AUTOCOMPLETE_SUCCESS = 'CLEAR_COHORT_AUTOCOMPLETE_SUCCESS'
const CLEAR_COHORT_AUTOCOMPLETE_FAILED = 'CLEAR_COHORT_AUTOCOMPLETE_FAILED'

export const clearCohortAutoComplete = () => (dispatch) => {
	return dispatch({ type: CLEAR_COHORT_AUTOCOMPLETE_REQUESTED, data: {} })
}

const CLEAR_COHORT_QUESTION_REQUESTED = 'CLEAR_COHORT_QUESTION_REQUESTED'
const CLEAR_COHORT_QUESTION_SUCCESS = 'CLEAR_COHORT_QUESTION_SUCCESS'
const CLEAR_COHORT_QUESTION_FAILED = 'CLEAR_COHORT_QUESTION_FAILED'
export const clearCohortQuestion = () => (dispatch) => {
	return dispatch({ type: CLEAR_COHORT_QUESTION_REQUESTED, data: null })
}

const CLEAR_COHORT_REQUESTED = 'CLEAR_COHORT_REQUESTED'
const CLEAR_COHORT_SUCCESS = 'CLEAR_COHORT_SUCCESS'
const CLEAR_COHORT_FAILED = 'CLEAR_COHORT_FAILED'
export const clearCohort = () => (dispatch) => {
	return dispatch({ type: CLEAR_COHORT_REQUESTED, data: null })
}

const CLEAR_POPULATION_QUESTION_REQUESTED = 'CLEAR_POPULATION_QUESTION_REQUESTED'
const CLEAR_POPULATION_QUESTION_SUCCESS = 'CLEAR_POPULATION_QUESTION_SUCCESS'
const CLEAR_POPULATION_QUESTION_FAILED = 'CLEAR_POPULATION_QUESTION_FAILED'
export const clearPopulationQuestion = () => (dispatch) => {
	return dispatch({ type: CLEAR_POPULATION_QUESTION_REQUESTED, data: null })
}

const UPDATE_COHORT_REQUESTED = 'UPDATE_COHORT_REQUESTED'
const UPDATE_COHORT_SUCCESS = 'UPDATE_COHORT_SUCCESS'
const UPDATE_COHORT_FAILED = 'UPDATE_COHORT_FAILED'
export const updateCohort = () => (dispatch) => {
	return dispatch(hideModal())
}

const SET_DOMAIN_REQUESTED = 'SET_DOMAIN_REQUESTED'
const SET_DOMAIN_SUCCESS = 'SET_DOMAIN_SUCCESS'
const SET_DOMAIN_FAILED = 'SET_DOMAIN_FAILED'
export const setCohortDomain = (reportId, cohortId, option) => (dispatch) => {
	dispatch({ type: SET_DOMAIN_REQUESTED, data: {} })
	return report.setCohortDomain(reportId, cohortId, option).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: SET_DOMAIN_SUCCESS, data: {} })

			setReportConfiguration(dispatch, response)
			dispatch(fetchReportData(reportId))

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.success', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.failed', isSuccess: false }
			})
		}
	})
}

const UPDATE_POPULATION_REQUESTED = 'UPDATE_POPULATION_REQUESTED'
const UPDATE_POPULATION_SUCCESS = 'UPDATE_POPULATION_SUCCESS'
const UPDATE_POPULATION_FAILED = 'UPDATE_POPULATION_FAILED'
export const updatePopulation = () => (dispatch) => {
	return dispatch(hideModal())
}

const SET_COHORT_MAIN_FILTER_REQUESTED = 'SET_COHORT_MAIN_FILTER_REQUESTED'
export const setCohortMainFilter = (value) => (dispatch) => {
	return dispatch({ type: SET_COHORT_MAIN_FILTER_REQUESTED, data: value })
}

const SET_CURRENT_COHORT_REQUESTED = 'SET_CURRENT_COHORT_REQUESTED'

const CREATE_COHORT_REQUESTED = 'CREATE_COHORT_REQUESTED'
const CREATE_COHORT_SUCCESS = 'CREATE_COHORT_SUCCESS'
const CREATE_COHORT_FAILED = 'CREATE_COHORT_FAILED'

export const createCohort = (reportId, name, question) => (dispatch) => {
	dispatch({ type: CREATE_COHORT_REQUESTED, data: {} })

	let user = cookies.get('user')
	let cases = question.caseId ? [question.caseId] : null
	let hasCaseFilter = question.caseId ? true : false

	return report.createCohort(reportId, name, cases, hasCaseFilter).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: CREATE_COHORT_SUCCESS, data: { id: response.id, name: name } })
			if (response.id) {
				question.cohortId = response.id
				if(!hasCaseFilter){
					dispatch(processCohortFilter(question))
				} else {
					dispatch(fetchReportConfiguration(reportId))
					dispatch({ type: SET_CURRENT_COHORT_REQUESTED, data: { cohort: response.id, cohortQuestion: null, cohortCase: null } })
				}
				
				dispatch(fetchReportData(reportId))
			}
		} else {
			return dispatch({ type: CREATE_COHORT_FAILED, data: {} })
		}
	})
}

const processCohortFilter = (question) => (dispatch) => {
	if (question) {
		if (question.isScoringGroup) {
			dispatch(
				addCohortFilterScoringGroupValue(
					question.reportId,
					question.cohortId,
					question.primaryValue,
					question.secondaryValue,
					question.sortOrder,
					question.comparisonType,
					question.entityId
				)
			)
		}
		else if (question.questionType == QuestionType.SingleValue || question.questionType == QuestionType.MultipleValue) {
			dispatch(
				addCohortFilterMultipleValue(
					question.reportId,
					question.cohortId,
					question.answerIds,
					question.sortOrder,
					question.comparisonType,
					question.questionType,
					question.entityId
				)
			)
		} else {
			dispatch(
				addCohortFilterSingleValue(
					question.reportId,
					question.cohortId,
					question.primaryValue,
					question.secondaryValue,
					question.primaryDateValue,
					question.secondaryDateValue,
					question.textValue,
					question.sortOrder,
					question.comparisonType,
					question.questionType,
					question.entityId
				)
			)
		}
	}
}

const CREATE_DEFAULT_COHORT_REQUESTED = 'CREATE_DEFAULT_COHORT_REQUESTED'
const CREATE_DEFAULT_COHORT_SUCCESS = 'CREATE_DEFAULT_COHORT_SUCCESS'
const CREATE_DEFAULT_COHORT_FAILED = 'CREATE_DEFAULT_COHORT_FAILED'

export const createDefaultCohort = (reportId, name, color) => (dispatch) => {
	dispatch({ type: CREATE_DEFAULT_COHORT_REQUESTED, data: {} })

	return report.createCohort(reportId, name, null, false, color).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: CREATE_DEFAULT_COHORT_SUCCESS, data: { id: response.id, name: name } })
			if (response.id) {
				dispatch(fetchReportConfiguration(reportId))
				dispatch(fetchReportData(reportId))
				dispatch(setCohort(response.id))
			}
		} else {
			return dispatch({ type: CREATE_DEFAULT_COHORT_FAILED, data: {} })
		}
	})
}

const FETCH_REPORT_CONFIGURATION_REQUESTED = 'FETCH_REPORT_CONFIGURATION_REQUESTED'
const FETCH_REPORT_CONFIGURATION_SUCCESS = 'FETCH_REPORT_CONFIGURATION_SUCCESS'
const FETCH_REPORT_CONFIGURATION_FAILED = 'FETCH_REPORT_CONFIGURATION_FAILED'

export const fetchReportConfiguration = (id) => (dispatch) => {
	dispatch({ type: FETCH_REPORT_CONFIGURATION_REQUESTED, data: {} })

	let user = cookies.get('user')

	return report.fetchConfiguration(id).then((response) => {
		if (response && response.isSuccessful) {
			setReportConfiguration(dispatch, response)
		} else {
			return dispatch({ type: FETCH_REPORT_CONFIGURATION_FAILED, data: {} })
		}
	})
}

const ADD_COHORT_SINGLE_VALUE_REQUESTED = 'ADD_COHORT_SINGLE_VALUE_REQUESTED'
const ADD_COHORT_SINGLE_VALUE_SUCCESS = 'ADD_COHORT_SINGLE_VALUE_SUCCESS'
const ADD_COHORT_SINGLE_VALUE_FAILED = 'ADD_COHORT_SINGLE_VALUE_FAILED'

export const addCohortFilterSingleValue = (
	reportId,
	cohortId,
	primaryValue,
	secondaryValue,
	primaryDateValue,
	secondaryDateValue,
	textValue,
	sortOrder,
	comparisonType,
	questionType,
	questionId,
	surveyInstanceId = null
) => (dispatch) => {
	dispatch({ type: ADD_COHORT_SINGLE_VALUE_REQUESTED, data: {} })

	let user = cookies.get('user')

	return report
		.addCohortSingleValueFilter(
			reportId,
			cohortId,
			primaryValue,
			secondaryValue,
			primaryDateValue,
			secondaryDateValue,
			textValue,
			sortOrder,
			comparisonType,
			questionType,
			questionId,
			surveyInstanceId
		)
		.then((response) => {
			if (response && response.isSuccessful) {
				dispatch({ type: ADD_COHORT_SINGLE_VALUE_SUCCESS, data: { cohort: cohortId, cohortQuestion: null } })
				dispatch(fetchReportConfiguration(reportId))
				dispatch(fetchReportData(reportId))
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.success', isSuccess: true }
				})
			} else {
				dispatch({ type: CREATE_COHORT_FAILED, data: {} })

				clearCohort()
				clearCohortQuestion()

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.failed', isSuccess: false }
				})
			}
		})
}

const ADD_COHORT_SCORING_GROUP_VALUE_REQUESTED = 'ADD_COHORT_SCORING_GROUP_VALUE_REQUESTED'
const ADD_COHORT_SCORING_GROUP_VALUE_SUCCESS = 'ADD_COHORT_SCORING_GROUP_VALUE_SUCCESS'

export const addCohortFilterScoringGroupValue = (
	reportId,
	cohortId,
	primaryValue,
	secondaryValue,
	sortOrder,
	comparisonType,
	scoringGroupId,
	surveyInstanceId = null
) => (dispatch) => {
	dispatch({ type: ADD_COHORT_SCORING_GROUP_VALUE_REQUESTED, data: {} })

	return report
		.addCohortScoringGroupValueFilter(
			reportId,
			cohortId,
			primaryValue,
			secondaryValue,
			sortOrder,
			comparisonType,
			scoringGroupId,
			surveyInstanceId
		)
		.then((response) => {
			if (response && response.isSuccessful) {
				dispatch({ type: ADD_COHORT_SCORING_GROUP_VALUE_SUCCESS, data: { cohort: cohortId, cohortQuestion: null } })
				dispatch(fetchReportConfiguration(reportId))
				dispatch(fetchReportData(reportId))
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.success', isSuccess: true }
				})
			} else {
				dispatch({ type: CREATE_COHORT_FAILED, data: {} })

				clearCohort()
				clearCohortQuestion()

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.failed', isSuccess: false }
				})
			}
		})
}



const UPDATE_COHORT_SINGLE_VALUE_REQUESTED = 'UPDATE_COHORT_SINGLE_VALUE_REQUESTED'
const UPDATE_COHORT_SINGLE_VALUE_SUCCESS = 'UPDATE_COHORT_SINGLE_VALUE_SUCCESS'
const UPDATE_COHORT_SINGLE_VALUE_FAILED = 'UPDATE_COHORT_SINGLE_VALUE_FAILED'
export const updateCohortFilterSingleValue = (
	filterId,
	primaryValue,
	secondaryValue,
	primaryDateValue,
	secondaryDateValue,
	textValue,
	reportId,
	sortOrder,
	comparisonType,
	surveyInstanceId = null
) => (dispatch) => {
	dispatch({ type: UPDATE_COHORT_SINGLE_VALUE_REQUESTED, data: {} })

	return report
		.updateCohortSingleValueFilter(
			filterId,
			primaryValue,
			secondaryValue,
			primaryDateValue,
			secondaryDateValue,
			textValue,
			reportId,
			sortOrder,
			comparisonType,
			surveyInstanceId
		)
		.then((response) => {
			if (response && response.isSuccessful) {
				dispatch({ type: UPDATE_COHORT_SINGLE_VALUE_SUCCESS, data: {} })
				dispatch(fetchReportConfiguration(reportId))
				dispatch(fetchReportData(reportId))
				clearCohort()
				clearCohortQuestion()

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.success', isSuccess: true }
				})
			} else {
				dispatch({ type: CREATE_COHORT_FAILED, data: {} })

				clearCohort()
				clearCohortQuestion()

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.failed', isSuccess: false }
				})
			}
		})
}

const UPDATE_COHORT_SCORING_GROUP_VALUE_REQUESTED = 'UPDATE_COHORT_SCORING_GROUP_VALUE_REQUESTED'
const UPDATE_COHORT_SCORING_GROUP_VALUE_SUCCESS = 'UPDATE_COHORT_SCORING_GROUP_VALUE_SUCCESS'
const UPDATE_COHORT_SCORING_GROUP_VALUE_FAILED = 'UPDATE_COHORT_SCORING_GROUP_VALUE_FAILED'

export const updateCohortFilterScoringGroupValue = (
	filterId,
	primaryValue,
	secondaryValue,
	reportId,
	sortOrder,
	comparisonType,
	surveyInstanceId = null
) => (dispatch) => {
	dispatch({ type: UPDATE_COHORT_SCORING_GROUP_VALUE_REQUESTED, data: {} })

	return report
		.updateCohortScoringGroupValueFilter(
			filterId,
			primaryValue,
			secondaryValue,
			reportId,
			sortOrder,
			comparisonType,
			surveyInstanceId
		)
		.then((response) => {
			if (response && response.isSuccessful) {
				dispatch({ type: UPDATE_COHORT_SCORING_GROUP_VALUE_SUCCESS, data: {} })
				dispatch(fetchReportConfiguration(reportId))
				dispatch(fetchReportData(reportId))
				clearCohort()
				clearCohortQuestion()

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.success', isSuccess: true }
				})
			} else {
				dispatch({ type: CREATE_COHORT_FAILED, data: {} })

				clearCohort()
				clearCohortQuestion()

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.failed', isSuccess: false }
				})
			}
		})
}



const UPDATE_POPULATION_SINGLE_VALUE_REQUESTED = 'UPDATE_POPULATION_SINGLE_VALUE_REQUESTED'
const UPDATE_POPULATION_SINGLE_VALUE_SUCCESS = 'UPDATE_POPULATION_SINGLE_VALUE_SUCCESS'
const UPDATE_POPULATION_SINGLE_VALUE_FAILED = 'UPDATE_POPULATION_SINGLE_VALUE_FAILED'

export const updatePopulationFilterSingleValue = (
	filterId,
	primaryValue,
	secondaryValue,
	primaryDateValue,
	secondaryDateValue,
	textValue,
	reportId,
	sortOrder,
	comparisonType,
	surveyInstanceId = null
) => (dispatch) => {
	dispatch({ type: UPDATE_POPULATION_SINGLE_VALUE_REQUESTED, data: {} })

	return report
		.updateCohortSingleValueFilter(
			filterId,
			primaryValue,
			secondaryValue,
			primaryDateValue,
			secondaryDateValue,
			textValue,
			reportId,
			sortOrder,
			comparisonType,
			surveyInstanceId
		)
		.then((response) => {
			if (response && response.isSuccessful) {
				dispatch({ type: UPDATE_POPULATION_SINGLE_VALUE_SUCCESS, data: {} })
				dispatch(fetchReportConfiguration(reportId))
				dispatch(fetchReportData(reportId))
				clearCohort()
				clearCohortQuestion()

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.success', isSuccess: true }
				})
			} else {
				dispatch({ type: UPDATE_POPULATION_SINGLE_VALUE_FAILED, data: {} })

				clearCohort()
				clearCohortQuestion()

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.failed', isSuccess: false }
				})
			}
		})
}

const UPDATE_COHORT_MULTIPLE_VALUE_REQUESTED = 'UPDATE_COHORT_MULTIPLE_VALUE_REQUESTED'
const UPDATE_COHORT_MULTIPLE_VALUE_SUCCESS = 'UPDATE_COHORT_MULTIPLE_VALUE_SUCCESS'
const UPDATE_COHORT_MULTIPLE_VALUE_FAILED = 'UPDATE_COHORT_MULTIPLE_VALUE_FAILED'

export const updateCohortFilterMultipleValue = (
	filterId,
	reportId,
	answerIds,
	sortOrder,
	comparisonType,
	questionType,
	questionId,
	surveyInstanceId = null
) => (dispatch) => {
	dispatch({ type: UPDATE_COHORT_MULTIPLE_VALUE_REQUESTED, data: {} })

	let user = cookies.get('user')
	return report
		.updateCohortMultipleValueFilter(
			filterId,
			reportId,
			answerIds,
			sortOrder,
			comparisonType,
			questionType,
			questionId,
			surveyInstanceId
		)
		.then((response) => {
			if (response && response.isSuccessful) {
				dispatch({
					type: UPDATE_COHORT_MULTIPLE_VALUE_SUCCESS,
					// data: { cohort: cohortId, cohortQuestion: null }
					data: { cohortQuestion: null }
				})

				dispatch(fetchReportConfiguration(reportId))
				dispatch(fetchReportData(reportId))
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.success', isSuccess: true }
				})
			} else {
				dispatch({ type: UPDATE_COHORT_MULTIPLE_VALUE_FAILED, data: {} })

				clearCohort()
				clearCohortQuestion()

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.failed', isSuccess: false }
				})
			}
		})
}

const UPDATE_POPULATION_SCORING_GROUP_VALUE_REQUESTED = 'UPDATE_POPULATION_SCORING_GROUP_VALUE_REQUESTED'
const UPDATE_POPULATION_SCORING_GROUP_VALUE_SUCCESS = 'UPDATE_POPULATION_SCORING_GROUP_VALUE_SUCCESS'
const UPDATE_POPULATION_SCORING_GROUP_VALUE_FAILED = 'UPDATE_POPULATION_SCORING_GROUP_VALUE_FAILED'

export const updatePopulationFilterScoringGroupValue = (
	filterId,
	primaryValue,
	secondaryValue,
	reportId,
	sortOrder,
	comparisonType,
	surveyInstanceId = null
) => (dispatch) => {
	dispatch({ type: UPDATE_POPULATION_SCORING_GROUP_VALUE_REQUESTED, data: {} })

	return report
		.updateCohortScoringGroupValueFilter(
			filterId,
			primaryValue,
			secondaryValue,
			reportId,
			sortOrder,
			comparisonType,
			surveyInstanceId
		)
		.then((response) => {
			if (response && response.isSuccessful) {
				dispatch({ type: UPDATE_POPULATION_SCORING_GROUP_VALUE_SUCCESS, data: {} })
				dispatch(fetchReportConfiguration(reportId))
				dispatch(fetchReportData(reportId))
				clearCohort()
				clearCohortQuestion()

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.success', isSuccess: true }
				})
			} else {
				dispatch({ type: UPDATE_POPULATION_SCORING_GROUP_VALUE_FAILED, data: {} })

				clearCohort()
				clearCohortQuestion()

				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.failed', isSuccess: false }
				})
			}
		})
}



const ADD_COHORT_MULTIPLE_VALUE_REQUESTED = 'ADD_COHORT_MULTIPLE_VALUE_REQUESTED'
const ADD_COHORT_MULTIPLE_VALUE_SUCCESS = 'ADD_COHORT_MULTIPLE_VALUE_SUCCESS'
const ADD_COHORT_MULTIPLE_VALUE_FAILED = 'ADD_COHORT_MULTIPLE_VALUE_FAILED'

export const addCohortFilterMultipleValue = (
	reportId,
	cohortId,
	answerIds,
	sortOrder,
	comparisonType,
	questionType,
	questionId,
	surveyInstanceId = null
) => (dispatch) => {
	dispatch({ type: ADD_COHORT_MULTIPLE_VALUE_REQUESTED, data: {} })

	let user = cookies.get('user')
	return report
		.addCohortMultipleValueFilter(
			reportId,
			cohortId,
			answerIds,
			sortOrder,
			comparisonType,
			questionType,
			questionId,
			surveyInstanceId
		)
		.then((response) => {
			if (response && response.isSuccessful) {
				dispatch({ type: ADD_COHORT_MULTIPLE_VALUE_SUCCESS, data: { cohort: cohortId, cohortQuestion: null } })
				dispatch(fetchReportConfiguration(reportId))
				dispatch(fetchReportData(reportId))
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.success', isSuccess: true }
				})
			} else {
				dispatch({ type: ADD_COHORT_MULTIPLE_VALUE_FAILED, data: {} })

				clearCohort()
				clearCohortQuestion()
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.failed', isSuccess: false }
				})
			}
		})
}



const ADD_POPULATION_SINGLE_VALUE_REQUESTED = 'ADD_POPULATION_SINGLE_VALUE_REQUESTED'
const ADD_POPULATION_SINGLE_VALUE_SUCCESS = 'ADD_POPULATION_SINGLE_VALUE_SUCCESS'
const ADD_POPULATION_SINGLE_VALUE_FAILED = 'ADD_POPULATION_SINGLE_VALUE_FAILED'

export const addPopulationFilterSingleValue = (
	reportId,
	primaryValue,
	secondaryValue,
	primaryDateValue,
	secondaryDateValue,
	textValue,
	sortOrder,
	comparisonType,
	questionType,
	questionId,
	surveyInstanceId = null
) => (dispatch) => {
	dispatch({ type: ADD_POPULATION_SINGLE_VALUE_REQUESTED, data: {} })

	let user = cookies.get('user')

	return report
		.addPopulationSingleValueFilter(
			reportId,
			primaryValue,
			secondaryValue,
			primaryDateValue,
			secondaryDateValue,
			textValue,
			sortOrder,
			comparisonType,
			questionType,
			questionId,
			surveyInstanceId
		)
		.then((response) => {
			if (response && response.isSuccessful) {
				dispatch({
					type: ADD_POPULATION_SINGLE_VALUE_SUCCESS,
					data: { cohortQuestion: null }
				})
				dispatch(fetchReportConfiguration(reportId))
				dispatch(fetchReportData(reportId))
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.success', isSuccess: true }
				})
			} else {
				dispatch({ type: ADD_POPULATION_SINGLE_VALUE_FAILED, data: {} })
				clearCohort()
				clearCohortQuestion()
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.failed', isSuccess: false }
				})
			}
		})
}

const ADD_POPULATION_MULTIPLE_VALUE_REQUESTED = 'ADD_POPULATION_MULTIPLE_VALUE_REQUESTED'
const ADD_POPULATION_MULTIPLE_VALUE_SUCCESS = 'ADD_POPULATION_MULTIPLE_VALUE_SUCCESS'
const ADD_POPULATION_MULTIPLE_VALUE_FAILED = 'ADD_POPULATION_MULTIPLE_VALUE_FAILED'

export const addPopulationFilterMultipleValue = (
	reportId,
	answerIds,
	sortOrder,
	comparisonType,
	questionType,
	questionId,
	surveyInstanceId = null
) => (dispatch) => {
	dispatch({ type: ADD_POPULATION_MULTIPLE_VALUE_REQUESTED, data: {} })

	let user = cookies.get('user')

	return report
		.addPopulationMultipleValueFilter(
			reportId,
			answerIds,
			sortOrder, 
			comparisonType, 
			questionType, 
			questionId,
			surveyInstanceId
		)
		.then((response) => {
			if (response && response.isSuccessful) {
				dispatch({
					type: ADD_POPULATION_MULTIPLE_VALUE_SUCCESS,
					data: { cohortQuestion: null }
				})
				dispatch(fetchReportConfiguration(reportId))
				dispatch(fetchReportData(reportId))
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.success', isSuccess: true }
				})
			} else {
				dispatch({ type: ADD_POPULATION_MULTIPLE_VALUE_FAILED, data: {} })
				clearCohort()
				clearCohortQuestion()
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.failed', isSuccess: false }
				})
			}
		})
}

const ADD_POPULATION_SCORING_GROUP_VALUE_REQUESTED = 'ADD_POPULATION_SCORING_GROUP_VALUE_REQUESTED'
const ADD_POPULATION_SCORING_GROUP_VALUE_SUCCESS = 'ADD_POPULATION_SCORING_GROUP_VALUE_SUCCESS'
const ADD_POPULATION_SCORING_GROUP_VALUE_FAILED = 'ADD_POPULATION_SCORING_GROUP_VALUE_FAILED'

export const addPopulationFilterScoringGroupValue = (
	reportId,
	primaryValue,
	secondaryValue,
	sortOrder,
	comparisonType,
	scoringGroupId,
	surveyInstanceId = null
) => (dispatch) => {
	dispatch({ type: ADD_POPULATION_SCORING_GROUP_VALUE_REQUESTED, data: {} })

	return report
		.addPopulationScoringGroupValueFilter(
			reportId,
			primaryValue,
			secondaryValue,
			sortOrder,
			comparisonType,
			scoringGroupId,
			surveyInstanceId
		)
		.then((response) => {
			if (response && response.isSuccessful) {
				dispatch({
					type: ADD_POPULATION_SCORING_GROUP_VALUE_SUCCESS,
					data: { cohortQuestion: null }
				})
				dispatch(fetchReportConfiguration(reportId))
				dispatch(fetchReportData(reportId))
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.success', isSuccess: true }
				})
			} else {
				dispatch({ type: ADD_POPULATION_SCORING_GROUP_VALUE_FAILED, data: {} })
				clearCohort()
				clearCohortQuestion()
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.failed', isSuccess: false }
				})
			}
		})
}



const UPDATE_POPULATION_MULTIPLE_VALUE_REQUESTED = 'UPDATE_POPULATION_MULTIPLE_VALUE_REQUESTED'
const UPDATE_POPULATION_MULTIPLE_VALUE_SUCCESS = 'UPDATE_POPULATION_MULTIPLE_VALUE_SUCCESS'
const UPDATE_POPULATION_MULTIPLE_VALUE_FAILED = 'UPDATE_POPULATION_MULTIPLE_VALUE_FAILED'

export const updatePopulationFilterMultipleValue = (
	filterId,
	reportId,
	answerIds,
	sortOrder,
	comparisonType,
	questionType,
	questionId,
	surveyInstanceId = null
) => (dispatch) => {
	dispatch({ type: UPDATE_POPULATION_MULTIPLE_VALUE_REQUESTED, data: {} })

	let user = cookies.get('user')

	return report
		.updatePopulationMultipleValueFilter(
			filterId,
			reportId,
			answerIds,
			sortOrder,
			comparisonType,
			questionType,
			questionId,
			surveyInstanceId
		)
		.then((response) => {
			if (response && response.isSuccessful) {
				dispatch({
					type: UPDATE_POPULATION_MULTIPLE_VALUE_SUCCESS,
					data: { cohortQuestion: null }
				})
				dispatch(fetchReportConfiguration(reportId))
				dispatch(fetchReportData(reportId))
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.success', isSuccess: true }
				})
			} else {
				dispatch({ type: UPDATE_POPULATION_MULTIPLE_VALUE_FAILED, data: {} })
				clearCohort()
				clearCohortQuestion()
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.report.failed', isSuccess: false }
				})
			}
		})
}

const FETCH_CONFIGURATION_REQUESTED = 'FETCH_CONFIGURATION_REQUESTED'
const FETCH_CONFIGURATION_SUCCESS = 'FETCH_CONFIGURATION_SUCCESS'
const FETCH_CONFIGURATION_FAILED = 'FETCH_CONFIGURATION_FAILED'

export const fetchConfiguration = (reportId, chartId) => (dispatch) => {
	dispatch({ type: FETCH_CONFIGURATION_REQUESTED, data: {} })

	report.fetchConfiguration(reportId).then((response) => {
		if (response && response.isSuccessful) {
			setReportConfiguration(dispatch, response)
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.fetchReportFailed', isSuccess: false }
			})
		}
	})
}

const FETCH_REPORT_DATA_REQUESTED = 'FETCH_REPORT_DATA_REQUESTED'
const FETCH_REPORT_DATA_SUCCESS = 'FETCH_REPORT_DATA_SUCCESS'
const FETCH_REPORT_DATA_FAILED = 'FETCH_REPORT_DATA_FAILED'

export const fetchReportData = (reportId) => (dispatch) => {
	dispatch({ type: FETCH_REPORT_DATA_REQUESTED, data: {} })

	report.getReportData(reportId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: 'FETCH_REPORT_DATA_SUCCESS',
				data: { chartData: response.chartData, stats: response.statisticsData }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.fetchReportFailed', isSuccess: false }
			})
		}
	})
}
const FETCH_REPORT_DATA_EXPORT_REQUESTED = 'FETCH_REPORT_DATA_EXPORT_REQUESTED'
const FETCH_REPORT_DATA_EXPORT_SUCCESS = 'FETCH_REPORT_DATA_EXPORT_SUCCESS'
const FETCH_REPORT_DATA_EXPORT_FAILED = 'FETCH_REPORT_DATA_EXPORT_FAILED'

export const fetchReportDataExport = (reportId) => (dispatch) => {
	dispatch({ type: FETCH_REPORT_DATA_EXPORT_REQUESTED, data: {} })
	
	report.getReportDataExport(reportId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_REPORT_DATA_EXPORT_SUCCESS, data: {} })
		} else {
			dispatch({ type: FETCH_REPORT_DATA_EXPORT_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.fetchReportFailed', isSuccess: false }
			})
		}
	})
}

const FETCH_CIRCLE_REPORT_DATA_EXPORT_REQUESTED = 'FETCH_CIRCLE_REPORT_DATA_EXPORT_REQUESTED'
const FETCH_CIRCLE_REPORT_DATA_EXPORT_SUCCESS = 'FETCH_CIRCLE_REPORT_DATA_EXPORT_SUCCESS'
const FETCH_CIRCLE_REPORT_DATA_EXPORT_FAILED = 'FETCH_CIRCLE_REPORT_DATA_EXPORT_FAILED'

export const fetchCircleReportDataExport = (circleId) => (dispatch) => {
	dispatch({ type: FETCH_CIRCLE_REPORT_DATA_EXPORT_REQUESTED, data: {} })

	report.getCircleReportDataExport(circleId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_CIRCLE_REPORT_DATA_EXPORT_SUCCESS, data: {} })
		} else {
			dispatch({ type: FETCH_CIRCLE_REPORT_DATA_EXPORT_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.fetchReportFailed', isSuccess: false }
			})
		}
	})
}

const EXPORT_PATIENT_REPORT_REQUESTED = 'EXPORT_PATIENT_REPORT_REQUESTED'
const EXPORT_PATIENT_REPORT_SUCCESS = 'EXPORT_PATIENT_REPORT_SUCCESS'
const EXPORT_PATIENT_REPORT_FAILED = 'EXPORT_PATIENT_REPORT_FAILED'

export const exportPatientReport = (patientId) => (dispatch) => {
	dispatch({ type: EXPORT_PATIENT_REPORT_REQUESTED, data: {} })
	
	report.getPatientReportDataExport(patientId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: EXPORT_PATIENT_REPORT_SUCCESS, data: {} })
		} else {
			dispatch({ type: EXPORT_PATIENT_REPORT_FAILED, data: {} })
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.fetchReportFailed', isSuccess: false }
			})
		}
	})
}

const REMOVE_COHORT_REQUESTED = 'REMOVE_COHORT_REQUESTED'
const REMOVE_COHORT_SUCCESS = 'REMOVE_COHORT_SUCCESS'
const REMOVE_COHORT_FAILED = 'REMOVE_COHORT_FAILED'

export const removeCohort = (reportId, cohortId) => (dispatch) => {
	dispatch({ type: REMOVE_COHORT_REQUESTED, data: {} })

	report.deleteCohort(reportId, cohortId).then((response) => {
		if (response && response.isSuccessful) {
			setReportConfiguration(dispatch, response)
			dispatch(fetchReportData(reportId))
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.cohort.removeSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.cohort.removeFailed', isSuccess: false }
			})
		}
	})
}

const REMOVE_COHORT_FILTER_REQUESTED = 'REMOVE_COHORT_FILTER_REQUESTED'
const REMOVE_COHORT_FILTER_SUCCESS = 'REMOVE_COHORT_FILTER_SUCCESS'
const REMOVE_COHORT_FILTER_FAILED = 'REMOVE_COHORT_FILTER_FAILED'

export const removeCohortFilter = (reportId, cohortId, filterId) => (dispatch) => {
	dispatch({ type: REMOVE_COHORT_FILTER_REQUESTED, data: {} })

	report.deleteCohortFilter(reportId, cohortId, filterId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: REMOVE_COHORT_FILTER_SUCCESS, data: null })

			setReportConfiguration(dispatch, response)
			dispatch(fetchReportData(reportId))
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.cohort.removeSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.cohort.removeFailed', isSuccess: false }
			})
		}
	})
}

const REMOVE_POPULATION_FILTER_REQUESTED = 'REMOVE_POPULATION_FILTER_REQUESTED'
const REMOVE_POPULATION_FILTER_SUCCESS = 'REMOVE_POPULATION_FILTER_SUCCESS'
const REMOVE_POPULATION_FILTER_FAILED = 'REMOVE_POPULATION_FILTER_FAILED'

export const removePopulationFilter = (reportId, filterId) => (dispatch) => {
	dispatch({ type: REMOVE_POPULATION_FILTER_REQUESTED, data: {} })

	report.deletePopulationFilter(reportId, filterId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: REMOVE_POPULATION_FILTER_SUCCESS, data: null })

			setReportConfiguration(dispatch, response)
			dispatch(fetchReportData(reportId))
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.cohort.removeSuccess', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.cohort.removeFailed', isSuccess: false }
			})
		}
	})
}

const SET_COHORT_REQUESTED = 'SET_COHORT_REQUESTED'
const SET_COHORT_SUCCESS = 'SET_COHORT_SUCCESS'
const SET_COHORT_FAILED = 'SET_COHORT_FAILED'
export const setCohort = (id) => (dispatch) => {
	dispatch({ type: SET_COHORT_REQUESTED, data: {} })

	if (id) {
		dispatch({ type: SET_COHORT_SUCCESS, data: id })
	} else {
		dispatch({ type: SET_COHORT_FAILED, data: {} })
	}
}

const SET_COHORT_FILTER_REQUESTED = 'SET_COHORT_FILTER_REQUESTED'
const SET_COHORT_FILTER_SUCCESS = 'SET_COHORT_FILTER_SUCCESS'
const SET_COHORT_FILTER_FAILED = 'SET_COHORT_FILTER_FAILED'
export const setCohortFilter = (cohortQuestion) => (dispatch) => {
	dispatch({ type: SET_COHORT_FILTER_REQUESTED, data: {} })

	if (cohortQuestion) {
		dispatch({ type: SET_COHORT_FILTER_SUCCESS, data: cohortQuestion })
	} else {
		dispatch({ type: SET_COHORT_FAILED, data: {} })
	}
}

const SET_POPULATION_FILTER_REQUESTED = 'SET_POPULATION_FILTER_REQUESTED'
const SET_POPULATION_FILTER_SUCCESS = 'SET_POPULATION_FILTER_SUCCESS'
const SET_POPULATION_FILTER_FAILED = 'SET_POPULATION_FILTER_FAILED'
export const setPopulationFilter = (populationQuestion) => (dispatch) => {
	dispatch({ type: SET_POPULATION_FILTER_REQUESTED, data: {} })

	if (populationQuestion) {
		dispatch({ type: SET_POPULATION_FILTER_SUCCESS, data: populationQuestion })
	} else {
		dispatch({ type: SET_POPULATION_FILTER_FAILED, data: {} })
	}
}

const UPDATE_COHORT_NAME_REQUESTED = 'UPDATE_COHORT_NAME_REQUESTED'
const UPDATE_COHORT_NAME_SUCCESS = 'UPDATE_COHORT_NAME_SUCCESS'
const UPDATE_COHORT_NAME_FAILED = 'UPDATE_COHORT_NAME_FAILED'
export const updateCohortName = (reportId, cohortId, name) => (dispatch) => {
	dispatch({ type: UPDATE_COHORT_NAME_REQUESTED, data: {} })

	report.updateCohortName(reportId, cohortId, name).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: UPDATE_COHORT_NAME_SUCCESS, data: null })

			dispatch(fetchReportConfiguration(reportId))
			dispatch(fetchReportData(reportId))

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.success', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.failed', isSuccess: false }
			})
		}
	})
}

const UPDATE_REPORT_NAME_REQUESTED = 'UPDATE_REPORT_NAME_REQUESTED'
const UPDATE_REPORT_NAME_SUCCESS = 'UPDATE_REPORT_NAME_SUCCESS'
const UPDATE_REPORT_NAME_FAILED = 'UPDATE_REPORT_NAME_FAILED'
export const updateReportName = (reportId, name) => (dispatch) => {
	dispatch({ type: UPDATE_REPORT_NAME_REQUESTED, data: {} })

	report.updateReportName(reportId, name).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: UPDATE_REPORT_NAME_SUCCESS, data: null })

			dispatch(fetchReportConfiguration(reportId))

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.success', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.failed', isSuccess: false }
			})
		}
	})
}

const UPDATE_XAXIS_NAME_REQUESTED = 'UPDATE_XAXIS_NAME_REQUESTED'
const UPDATE_XAXIS_NAME_SUCCESS = 'UPDATE_XAXIS_NAME_SUCCESS'
const UPDATE_XAXIS_NAME_FAILED = 'UPDATE_XAXIS_NAME_FAILED'
export const updateXAxis = (values) => (dispatch) => {
	dispatch({ type: UPDATE_XAXIS_NAME_REQUESTED, data: {} })

	report.updateXAxis(values).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: UPDATE_XAXIS_NAME_SUCCESS, data: null })

			dispatch(fetchReportConfiguration(values.id))
			dispatch(fetchReportData(values.id))
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.success', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.failed', isSuccess: false }
			})
		}
	})
}

const UPDATE_COHORT_VISIBILITY_REQUESTED = 'UPDATE_COHORT_VISIBILITY_REQUESTED'
const UPDATE_COHORT_VISIBILITY_SUCCESS = 'UPDATE_COHORT_VISIBILITY_SUCCESS'
const UPDATE_COHORT_VISIBILITY_FAILED = 'UPDATE_COHORT_VISIBILITY_FAILED'
export const updateCohortVisibility = (reportId, cohortId) => (dispatch) => {
	dispatch({ type: UPDATE_COHORT_VISIBILITY_REQUESTED, data: {cohortId} })

	report.updateCohortVisibility(reportId, cohortId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: UPDATE_COHORT_VISIBILITY_SUCCESS, data: null })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.failed', isSuccess: false }
			})
		}
	})
}

const FILL_VISIBLE_CHART_INDICES_REQUESTED = 'FILL_VISIBLE_CHART_INDICES_REQUESTED'

export const fillVisibleChartIndices = (length) => (dispatch) => {
	let newVisibleChartIndices = Array(length).fill(true)
	dispatch({ type: FILL_VISIBLE_CHART_INDICES_REQUESTED, data: newVisibleChartIndices })
}

const SET_COHORT_VISIBILITY_REQUESTED = 'SET_COHORT_VISIBILITY_REQUESTED'

export const setCohortVisibility = (index, isVisible) => (dispatch) => {
	dispatch({ type: SET_COHORT_VISIBILITY_REQUESTED, data: {index, isVisible} })
}

const SET_CHART_TYPE_REQUESTED = 'SET_CHART_TYPE_REQUESTED'

export const setChartType = (value) => (dispatch) => {
	dispatch({ type: SET_CHART_TYPE_REQUESTED, data: value })
}

const RESET_CHART_TYPE_REQUESTED = 'RESET_CHART_TYPE_REQUESTED'

export const resetChartType = (value) => (dispatch) => {
	dispatch({ type: RESET_CHART_TYPE_REQUESTED, data: value })
}

const UPDATE_DATA_FREEZE_SETTINGS_REQUESTED = 'UPDATE_DATA_FREEZE_SETTINGS_REQUESTED'
const UPDATE_DATA_FREEZE_SETTINGS_SUCCESS = 'UPDATE_DATA_FREEZE_SETTINGS_SUCCESS'
const UPDATE_DATA_FREEZE_SETTINGS_FAILED = 'UPDATE_DATA_FREEZE_SETTINGS_FAILED'
export const updateDataFreezeSettings = (values) => (dispatch) => {
	dispatch({ type: UPDATE_DATA_FREEZE_SETTINGS_REQUESTED, data: {} })

	report.updateDataFreezeSettings(values).then((response) => {
		if (response && response.isSuccessful) {
			dispatch({ type: UPDATE_DATA_FREEZE_SETTINGS_SUCCESS, data: null })

			dispatch(fetchReportConfiguration(values.id))
			dispatch(fetchReportData(values.id))
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.success', isSuccess: true }
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.report.failed', isSuccess: false }
			})
		}
	})
}

const CLEAR_REPORT_EXPORT_INITIATED_MESSAGE_REQUESTED = 'CLEAR_REPORT_EXPORT_INITIATED_MESSAGE_REQUESTED'

export const clearReportExportInitiatedMessage = (value) => (dispatch) => {
	return dispatch({ type: CLEAR_REPORT_EXPORT_INITIATED_MESSAGE_REQUESTED, data: {} })
}

const initial = {
	report: null,
	circles: [],
	yAxisAutocompleteValues: [],
	yAxisForCircleValues: [],
	cohortQuestionAutocompleteValues: [],
	currentCohort: null,
	cohortQuestion: null,
	cohortSurveys: null,
	cohortCase: null,
	populationQuestion: null,
	isFetchingChart: false,
	reportChartData: null,
	reportStatsData: null,
	currentConfiguration: {
		// circleId: 35,
		circleId: 0,
		scoringGroupId: 0,
		populationFilter: [],
		cohorts: []
	},
	mainFilter: '0',
	allowUpdateCohortCase: false,
	reportIsExporting: false,
	circleReportIsExporting: false,
	visibleChartIndices: [],
	chartType: CHART_TYPES.LINE_CHART,
	newReportExportIsInitiated: false
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case 'SIGN_OUT_REQUESTED':
			return initial
		case FETCH_REPORT_DATA_REQUESTED:
			return { ...state, reportChartData: null, reportStatsData: null, isFetchingChart: true }
		case FETCH_REPORT_DATA_SUCCESS:
			return {
				...state,
				reportChartData: action.data.chartData,
				reportStatsData: action.data.stats,
				isFetchingChart: false
			}
		case FETCH_REPORT_DATA_EXPORT_REQUESTED:
			return {
				...state,
				newReportExportIsInitiated: false,
				reportIsExporting: true
			}
		case FETCH_REPORT_DATA_EXPORT_SUCCESS:
			return {
				...state,
				newReportExportIsInitiated: true,
				reportIsExporting: false
			}
		case FETCH_REPORT_DATA_EXPORT_FAILED:
			return {
				...state,
				newReportExportIsInitiated: false,
				reportIsExporting: false
			}
		case FETCH_CIRCLE_REPORT_DATA_EXPORT_REQUESTED:
			return {
				...state,
				newReportExportIsInitiated: false,
				circleReportIsExporting: true
			}
		case FETCH_CIRCLE_REPORT_DATA_EXPORT_SUCCESS:
			return {
				...state,
				newReportExportIsInitiated: true,
				circleReportIsExporting: false
			}
		case FETCH_CIRCLE_REPORT_DATA_EXPORT_FAILED:
			return {
				...state,
				newReportExportIsInitiated: false,
				circleReportIsExporting: false
			}
		case EXPORT_PATIENT_REPORT_REQUESTED:
			return {
				...state,
				newReportExportIsInitiated: false
			}
		case EXPORT_PATIENT_REPORT_SUCCESS:
			return {
				...state,
				newReportExportIsInitiated: true
			}
		case EXPORT_PATIENT_REPORT_FAILED:
			return {
				...state,
				newReportExportIsInitiated: false
			}
		case SET_CIRCLE_REQUESTED:
			return { ...state, currentConfiguration: { ...state.currentConfiguration, circleId: action.data } }
		case SET_YAXIS_REQUESTED:
			return { ...state, currentConfiguration: { ...state.currentConfiguration, scoringGroupId: action.data } }
		case FETCH_COHORT_AUTOCOMPLETE_SUCCESS:
			return { ...state, cohortQuestionAutocompleteValues: [ ...action.data ] }
		case FETCH_YAXIS_SUCCESS:
			return { ...state, yAxisAutocompleteValues: action.data }
		case CLEAR_YAXIS_FOR_CIRCLE_REQUESTED:
			return { ...state, yAxisForCircleValues: [] }
		case FETCH_YAXIS_FOR_CIRCLE_REQUESTED:
			return { ...state, yAxisForCircleValues: [] }
		case FETCH_YAXIS_FOR_CIRCLE_SUCCESS:
			return { ...state, yAxisForCircleValues: action.data }
		case CLEAR_COHORT_AUTOCOMPLETE_SUCCESS:
			return { ...state, cohortQuestionAutocompleteValues: [] }
		case CLEAR_YAXIS_AUTOCOMPLETE_REQUESTED:
			return { ...state, cohortQuestionAutocompleteValues: [] }
		case FETCH_REPORT_CIRCLES_SUCCESS:
			return { ...state, circles: action.data }
		case FETCH_QUESTION_DEFAULTS_SUCCESS:
			return {
				...state,
				cohortQuestion: {
					...state.cohortQuestion,
					id: action.data.id,
					title: action.data.title,
					type: action.data.type,
					answers: action.data.answers,
					isScoringGroup: action.data.isScoringGroup
				}
			}
		case FETCH_QUESTION_DEFAULTS_REQUESTED:
			return {
				...state,
				cohortQuestion: action.data
			}
		case FETCH_QUESTION_SURVEYS_REQUESTED:
			return {
				...state,
				cohortSurveys: null
			}
		case FETCH_QUESTION_SURVEYS_SUCCESS:
			return {
				...state,
				cohortSurveys: action.data
			}
		case SET_COHORT_SCORING_GROUP_REQUESTED:
			return {
				...state,
				cohortQuestion: {
					...state.cohortQuestion,
					id: action.data.id,
					title: action.data.title,
					type: action.data.type,
					answers: action.data.answers,
					isScoringGroup: action.data.isScoringGroup
				}
			}
		case CREATE_COHORT_SUCCESS:
			return {
				...state,
				cohort: action.data,
				currentConfiguration: {
					...state.currentConfiguration,
					cohorts: [ ...(state.currentConfiguration.cohorts || []), action.data ]
				}
			}
		case FETCH_REPORT_CONFIGURATION_SUCCESS:
			return { ...state, currentConfiguration: action.data }
		case ADD_COHORT_SINGLE_VALUE_SUCCESS:
			return { ...state, cohortQuestion: action.data.cohortQuestion, currentCohort: action.data.cohort }
		case ADD_COHORT_MULTIPLE_VALUE_SUCCESS:
			return { ...state, cohortQuestion: action.data.cohortQuestion, currentCohort: action.data.cohort }
		case ADD_COHORT_SCORING_GROUP_VALUE_SUCCESS:
			return { ...state, cohortQuestion: action.data.cohortQuestion, currentCohort: action.data.cohort }
		case SET_CURRENT_COHORT_REQUESTED:
			return { ...state, cohortQuestion: action.data.cohortQuestion, cohortCase: action.data.cohortCase, currentCohort: action.data.cohort }
		case ADD_POPULATION_SINGLE_VALUE_SUCCESS:
			return { ...state, cohortQuestion: action.data.cohortQuestion }
		case ADD_POPULATION_SCORING_GROUP_VALUE_SUCCESS:
			return { ...state, cohortQuestion: action.data.cohortQuestion }
		case ADD_POPULATION_MULTIPLE_VALUE_SUCCESS:
			return { ...state, cohortQuestion: action.data.cohortQuestion }
		case FETCH_CONFIGURATION_SUCCESS:
			return {
				...state,
				currentConfiguration: action.data.configuration,
				circles: action.data.circles,
				yAxisAutocompleteValues: action.data.scoringGroups
			}
		case CLEAR_COHORT_QUESTION_REQUESTED:
			return { ...state, cohortQuestion: action.data }
		case CLEAR_COHORT_REQUESTED:
			return { ...state, currentCohort: action.data }
		case SET_COHORT_SUCCESS:
			return { ...state, currentCohort: action.data }
		case SET_COHORT_FILTER_SUCCESS:
			return { ...state, cohortQuestion: action.data }
		case CLEAR_POPULATION_QUESTION_REQUESTED:
			return { ...state, populationQuestion: action.data }
		case SET_POPULATION_FILTER_SUCCESS:
			return { ...state, populationQuestion: action.data }
		case REMOVE_COHORT_FILTER_SUCCESS:
			return { ...state, cohortQuestion: action.data }
		case REMOVE_POPULATION_FILTER_SUCCESS:
			return { ...state, populationQuestion: action.data }
		case SET_COHORT_CASE_REQUESTED:
			return { ...state, cohortCase: action.data }
		case SET_COHORT_MAIN_FILTER_REQUESTED:
			return { ...state, mainFilter: action.data }
		case SET_ALLOW_UPDATE_COHORT_CASE_REQUESTED:
			return { ...state, allowUpdateCohortCase: action.data}
		case FILL_VISIBLE_CHART_INDICES_REQUESTED:
			return { ...state, visibleChartIndices: action.data }
		case SET_COHORT_VISIBILITY_REQUESTED:
			return { 
				...state, 
				visibleChartIndices: [...state.visibleChartIndices.slice(0, action.data.index), action.data.isVisible, ...state.visibleChartIndices.slice(action.data.index + 1)]
			}
		case UPDATE_COHORT_VISIBILITY_REQUESTED:
			return {
				...state,
				currentConfiguration: {
					...state.currentConfiguration,
					cohorts: [ ...state.currentConfiguration.cohorts.map(item => item.id !== action.data.cohortId ? item : {...item, isHidden: !item.isHidden}) ]
				}
			}
		case SET_CHART_TYPE_REQUESTED:
			return { ...state, chartType: action.data }
		case RESET_CHART_TYPE_REQUESTED:
			return { ...state, chartType: CHART_TYPES.LINE_CHART }
		case CLEAR_REPORT_EXPORT_INITIATED_MESSAGE_REQUESTED:
			return { ...state, newReportExportIsInitiated: false }
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.reportBuilder

export const currentConfigurationSelector = createSelector(mainSelector, (state) => {
	return state.currentConfiguration
})

export const reportSettingsSelector = createSelector(mainSelector, (state) => {
	if (state.currentConfiguration) {
		return {
			...state.currentConfiguration,
			yAxisMinimumValue: isNaN(state.currentConfiguration.yAxisMinimumValue) ? state.currentConfiguration.yAxisMinimumValue : state.currentConfiguration.yAxisMinimumValue.toString(),
			yAxisMaximumValue: isNaN(state.currentConfiguration.yAxisMaximumValue) ? state.currentConfiguration.yAxisMaximumValue : state.currentConfiguration.yAxisMaximumValue.toString(),
			scoringGroupId: state.currentConfiguration.scoringGroupId || null,
			xAxisMinimumSurveyInstanceId: state.currentConfiguration.xAxisMinimumSurveyInstanceId
				? state.currentConfiguration.xAxisMinimumSurveyInstanceId
				: (!!state.currentConfiguration.xAxisValues && state.currentConfiguration.xAxisValues.length > 0
					? state.currentConfiguration.xAxisValues[0].surveyInstanceId
					: null),
			xAxisMaximumSurveyInstanceId: !!state.currentConfiguration.xAxisMaximumSurveyInstanceId
				? state.currentConfiguration.xAxisMaximumSurveyInstanceId
				: (!!state.currentConfiguration.xAxisValues && state.currentConfiguration.xAxisValues.length > 0
					? state.currentConfiguration.xAxisValues[state.currentConfiguration.xAxisValues.length - 1].surveyInstanceId
					: null),
		}
	}

	return null
})

export const mainFilterSelector = createSelector(mainSelector, (state) => {
	return state.mainFilter
})

export const allowUpdateCohortCaseSelector = createSelector(mainSelector, (state) => {
	return state.allowUpdateCohortCase
})

export const currentConfigurationNameSelector = createSelector(mainSelector, (state) => {
	return state.currentConfiguration && state.currentConfiguration.name
})

export const currentReportSelector = createSelector(mainSelector, (state) => {
	return state.currentConfiguration && state.currentConfiguration.id
})

export const cohortAutocompleteValuesSelector = createSelector(mainSelector, (state) => {
	return state.cohortQuestionAutocompleteValues
})

export const yaxisAutocompleteValuesSelector = createSelector(mainSelector, (state) => {
	if (state && state.yAxisAutocompleteValues) {
		return state.yAxisAutocompleteValues.sort((a, b) => (a.shortName > b.shortName ? 1 : -1)).map((r) => {
			return {
				id: r.id,
				name: r.shortName,
				yAxisMinimumValueDefault: r.yAxisMinimumValueDefault,
				yAxisMaximumValueDefault: r.yAxisMaximumValueDefault
			}
		})
	}
})

export const yAxisForCircleSelector = createSelector(mainSelector, (state) => {
	if (state && state.yAxisForCircleValues) {
		return state.yAxisForCircleValues.sort((a, b) => (a.shortName > b.shortName ? 1 : -1)).map((r) => {
			return {
				id: r.id,
				name: r.shortName
			}
		})
	}
})

export const fetchCohortsSelector = createSelector(mainSelector, (state) => {
	return state && state.currentConfiguration && state.currentConfiguration.cohorts
})

export const fetchPopulationsSelector = createSelector(mainSelector, (state) => {
	return state && state.currentConfiguration && state.currentConfiguration.populationFilters
})

export const fetchCohortQuestionsSelector = createSelector(mainSelector, (state) => (cohortId) => {
	return (
		state &&
		state.currentConfiguration &&
		state.currentConfiguration.cohorts &&
		state.currentConfiguration.cohorts.find((item) => item.id == cohortId)
	)
})

const processDateQuestion = (cohortQuestion) => {
	switch (cohortQuestion.comparisonType) {
		case ComparisonType.LessThan:
			return { lessThanDate: cohortQuestion.primaryDateValue }
		case ComparisonType.Between:
			return { minDate: cohortQuestion.primaryDateValue, maxDate: cohortQuestion.secondaryDateValue }
		case ComparisonType.GreaterThan:
			return { greaterThanDate: cohortQuestion.primaryDateValue }
		case ComparisonType.GreaterThanOrEqual:
			return { greaterThanOrEqualDate: cohortQuestion.primaryDateValue }
		case ComparisonType.LessThanOrEqual:
			return { lessThanOrEqualDate: cohortQuestion.primaryDateValue }
		case ComparisonType.Equal:
			return { equalDate: cohortQuestion.primaryDateValue }

		default:
			break
	}
}

const processNumberQuestion = (cohortQuestion) => {
	switch (cohortQuestion.comparisonType) {
		case ComparisonType.LessThan:
			return { lessThanNumber: cohortQuestion.primaryValue }
		case ComparisonType.Between:
			return { minNumber: cohortQuestion.primaryValue, maxNumber: cohortQuestion.secondaryValue }
		case ComparisonType.GreaterThan:
			return { greaterThanNumber: cohortQuestion.primaryValue }
		case ComparisonType.GreaterThanOrEqual:
			return { greaterThanOrEqualNumber: cohortQuestion.primaryValue }
		case ComparisonType.LessThanOrEqual:
			return { lessThanOrEqualNumber: cohortQuestion.primaryValue }
		case ComparisonType.Equal:
			return { equalNumber: cohortQuestion.primaryValue }
		default:
			break
	}
}

const processTextQuestion = (cohortQuestion) => {
	switch (cohortQuestion.comparisonType) {
		case ComparisonType.StartsWith:
			return { startsWithText: cohortQuestion.textValue }
		case ComparisonType.EndsWith:
			return { endsWithText: cohortQuestion.textValue }
		case ComparisonType.Contains:
			return { containsText: cohortQuestion.textValue }
		case ComparisonType.Equal:
			return { equalText: cohortQuestion.textValue }
		default:
			break
	}
}

const processMultipleQuestion = (cohortQuestion) => {
	var obj = {}
	obj.answers = {}

	if (cohortQuestion && cohortQuestion.answers && cohortQuestion.answers.length > 0) {
		cohortQuestion.answers.map((item) => {
			if (item.isSelected) {
				obj.answers['_' + item.answerId] = item.isSelected
			}
		})
	}
	return obj
}

export const initialCohortQuestionValues = createSelector(mainSelector, (state) => {
	let cohortQuestion = state.cohortQuestion

	if (cohortQuestion.isScoringGroup) {
		return processNumberQuestion(cohortQuestion)
	}
	else {
		switch (cohortQuestion.questionType) {
			case QuestionType.Date:
				return processDateQuestion(cohortQuestion)
			case QuestionType.Number:
				return processNumberQuestion(cohortQuestion)
			case QuestionType.Analog:
				return processNumberQuestion(cohortQuestion)
			case QuestionType.Grouped:
				return processNumberQuestion(cohortQuestion)
			case QuestionType.Text:
				return processTextQuestion(cohortQuestion)
			case QuestionType.SingleValue:
				return processMultipleQuestion(cohortQuestion)
			case QuestionType.MultipleValue:
				return processMultipleQuestion(cohortQuestion)
			default:
				break
		}
	}
})

export const initialPopulationQuestionValues = createSelector(mainSelector, (state) => {
	let populationQuestion = state.populationQuestion

	if (populationQuestion.isScoringGroup) {
		return processNumberQuestion(populationQuestion)
	}
	else {
		switch (populationQuestion.questionType) {
			case QuestionType.Date:
				return processDateQuestion(populationQuestion)
			case QuestionType.Number:
				return processNumberQuestion(populationQuestion)
			case QuestionType.Analog:
				return processNumberQuestion(populationQuestion)
			case QuestionType.Grouped:
				return processNumberQuestion(populationQuestion)
			case QuestionType.Text:
				return processTextQuestion(populationQuestion)
			case QuestionType.SingleValue:
				return processMultipleQuestion(populationQuestion)
			case QuestionType.MultipleValue:
				return processMultipleQuestion(populationQuestion)
			default:
				break
		}
	}
})

export const cohortQuestionSelector = createSelector(mainSelector, (state) => {
	return state && state.cohortQuestion
})

export const cohortCaseSelector = createSelector(mainSelector, (state) => {
	return state && state.cohortCase
})

export const populationQuestionSelector = createSelector(mainSelector, (state) => {
	return state && state.populationQuestion
})

export const countCohortQuestionSelector = createSelector(mainSelector, (state) => {
	return (
		state &&
		state.currentConfiguration &&
		state.currentConfiguration.cohorts &&
		state.currentConfiguration.cohorts.length
	)
})

export const currentCohortSelector = createSelector(mainSelector, (state) => {
	return state.currentCohort
})

export const currentCohortQuestionSelector = createSelector(mainSelector, (state) => {
	return state.currentCohortQuestion
})

export const reportCirclesSelector = createSelector(mainSelector, (state) => {
	return state.circles
})

export const circleFilterSetLabelSelector = createSelector(mainSelector, (state) => {
	if (state && state.circles && state.currentConfiguration && state.currentConfiguration.circleId) {
		let circle = state.circles.find((r) => {
			if (r.id === state.currentConfiguration.circleId) {
				return r
			}
		})
		if (circle) {
			if (circle.name.length > 20) {
				return circle.name.substring(0, 20) + '...'
			}
			return circle.name
		}
	}
	return null
})

export const reportCircleNameSelector = createSelector(mainSelector, (state) => {
	if (state && state.circles && state.currentConfiguration && state.currentConfiguration.circleId) {
		let circle = state.circles.find((r) => {
			if (r.id === state.currentConfiguration.circleId) {
				return r
			}
		})
		if (circle) {
			return circle.name
		}
	}
	return null
})


export const chartBoundsSelector = createSelector(mainSelector, (state) => {
	// if (
	// 	state &&
	// 	state.yAxisAutocompleteValues &&
	// 	state.currentConfiguration &&
	// 	state.currentConfiguration.scoringGroupId
	// ) {
	// 	return state.yAxisAutocompleteValues.find((r) => {
	// 		if (r.id === state.currentConfiguration.scoringGroupId) {
	// 			return r
	// 		}
	// 	})
	// }
	// return null
	if (state && state.currentConfiguration) {
		return {
			lowerBounds: state.currentConfiguration.yAxisMinimumValue, 
			upperBounds: state.currentConfiguration.yAxisMaximumValue
		}
	}

	return null
})

export const hasCircleBeenSelectedSelector = createSelector(mainSelector, (state) => {
	return (
		state &&
		state.currentConfiguration &&
		state.currentConfiguration.circleId &&
		state.currentConfiguration.circleId > 0
	)
})

export const yAxisSetLabelSelector = createSelector(mainSelector, (state) => {
	// if (
	// 	state &&
	// 	state.yAxisAutocompleteValues &&
	// 	state.currentConfiguration &&
	// 	state.currentConfiguration.scoringGroupId
	// ) {
	// 	let group = state.yAxisAutocompleteValues.find((r) => {
	// 		if (r.id === state.currentConfiguration.scoringGroupId) {
	// 			return r
	// 		}
	// 	})

	// 	if (group) {
	// 		if (group.shortName.length > 20) {
	// 			return group.shortName.substring(0, 20) + '...'
	// 		}
	// 		return group.shortName
	// 	}
	// }
	// return null

	if (state && state.currentConfiguration) {
		return state.currentConfiguration.scoringGroupName
	}
	return null
})

export const xAxisSetLabelSelector = createSelector(mainSelector, (state) => {
	if (state && state.currentConfiguration) {
		return state.currentConfiguration.xAxisName
	}
	return null
})

export const isFetchingChartSelector = createSelector(mainSelector, (state) => {
	return state.isFetchingChart
})

export const reportChartDataSelector = createSelector(mainSelector, (state) => {
	let result = []

	if (state && state.reportChartData && state.currentConfiguration.xAxisValues && state.currentConfiguration.xAxisValues.length > 0) {
		if (state.currentConfiguration.xAxisMinimumSurveyInstanceId || state.currentConfiguration.xAxisMaximumSurveyInstanceId) {
			let min = state.currentConfiguration.xAxisValues.find(x => x.surveyInstanceId === state.currentConfiguration.xAxisMinimumSurveyInstanceId)
			let max = state.currentConfiguration.xAxisValues.find(x => x.surveyInstanceId === state.currentConfiguration.xAxisMaximumSurveyInstanceId)
			result = state.reportChartData.map(el => {
				let data = el.data.filter(x =>
					(!min || getDueSortOrder(parseInt(x.labelValue), parseInt(x.labelUnit), x.labelTreatmentPhaseId) >= getDueSortOrder(min.dueValue, min.dueUnit, min.treatmentPhaseId))
					&& (!max || getDueSortOrder(parseInt(x.labelValue), parseInt(x.labelUnit), x.labelTreatmentPhaseId) <= getDueSortOrder(max.dueValue, max.dueUnit, max.treatmentPhaseId)))
				return { ...el, data: data }
			})

			result = result.sort((a, b) => a.id - b.id)
		} else {
			result = state.reportChartData.sort((a, b) => a.id - b.id)
		}

		// let min = state.currentConfiguration.yAxisMinimumValue
		// let max = state.currentConfiguration.yAxisMaximumValue
		// result = result.map(el => {
		// 	let populationPropertyName  = populationSetup(el.domainFilter)

		// 	let data = el.data.map(x => {
		// 		let newX = {
		// 			...x,
		// 		}
	
		// 		if (newX[populationPropertyName] < min || newX[populationPropertyName] > max) {
		// 			newX[populationPropertyName] = null
		// 		}

		// 		return newX
		// 	})

		// 	return {
		// 		...el,
		// 		data: data
		// 	}
		// })
	}

	return result
})

export const reportStatisticsDataSelector = createSelector(mainSelector, (state) => {
	if (state && state.reportStatsData) {
		return state.reportStatsData
	}
})

export const cohortCaseCountSelector = createSelector(mainSelector, (state) => (cohortId) => {
	if (
		state &&
		state.reportStatsData &&
		state.reportStatsData.cohorts &&
		state.reportStatsData.cohorts.length > 0 &&
		cohortId > 0
	) {
		return state.reportStatsData.cohorts.find((r) => {
			if (r.id === cohortId) {
				return r
			}
		})
	}
	return null
})

export const sampleDataSelector = createSelector(mainSelector, (state) => {
	var d = [
		{
			id: 133,
			isDefault: false,
			data: [
				{
					xAxis: '1',
					labelValue: '0',
					labelUnit: '0',
					labelTreatmentPhaseId: 0,
					myPopulation: 24.08,
					allPopulation: 44.08
				},
				{
					xAxis: '2',
					labelValue: '0',
					labelUnit: '0',
					labelTreatmentPhaseId: 1,
					myPopulation: 37.16,
					allPopulation: 44.16
				},
				{
					xAxis: '3',
					labelValue: '1',
					labelUnit: '1',
					labelTreatmentPhaseId: 2,
					myPopulation: 52.12,
					allPopulation: 44.12
				},
				{
					xAxis: '4',
					labelValue: '2',
					labelUnit: '1',
					labelTreatmentPhaseId: 2,
					myPopulation: 81.18,
					allPopulation: 44.18
				}
			]
		}
	]
	return d
})

export const cohortColorSelector = createSelector(mainSelector, (state) => {
	return [ '#007AFF', '#0F0F0F', '#5DA510', '#9CA9BF' ]
})

export const cohortNameSelector = createSelector(mainSelector, (state) => {
	return [ 'A', 'B', 'C', 'D' ]
})

export const currentCohortNameSelector = createSelector(mainSelector, (state) => {
	if (
		state &&
		state.currentConfiguration &&
		state.currentConfiguration.cohorts &&
		state.currentConfiguration.cohorts.length > 0 &&
		state.currentCohort
	) {
		var index = state.currentConfiguration.cohorts.findIndex((d) => d.id == state.currentCohort)
		return state.currentConfiguration.cohorts[index]
	}
})

export const chartHasRestrictionsSelector = createSelector(mainSelector, (state) => {
	return state && state.currentConfiguration && state.currentConfiguration.hasRestrictions
})

export const reportIsExportingSelector = createSelector(mainSelector, (state) => {
	return state && state.reportIsExporting
})

export const circleReportIsExportingSelector = createSelector(mainSelector, (state) => {
	return state && state.circleReportIsExporting
})

export const visibleChartIndicesSelector = createSelector(mainSelector, (state) => {
	return state && state.visibleChartIndices
})

export const reportXAxisValuesSelector = createSelector(mainSelector, (state, intl) => {
	var v = intl
	if (state && state.currentConfiguration && state.currentConfiguration.xAxisValues) {
		return state.currentConfiguration.xAxisValues.map(x => ({
			id: x.surveyInstanceId,
			value: x.dueValue,
			unit: x.dueUnit,
			sortOrder: getDueSortOrder(x.dueValue, x.dueUnit, x.treatmentPhaseId)
		}))
	}

	return []
})

export const chartTypeSelector = createSelector(mainSelector, (state) => {
	return state && state.chartType
})

export const questionSurveysSelector = createSelector(mainSelector, (state) => {
	let result = []

	if (state && state.cohortSurveys) {
		result = state.cohortSurveys.map(survey => {
			return {
				id: survey.id,
				name: survey.modifiedName || survey.title
			}
		})
	}

	return result
})

export const reportCircleIdSelector = createSelector(mainSelector, (state) => {
	if (state && state.circles && state.currentConfiguration && state.currentConfiguration.circleId) {
		return state.currentConfiguration.circleId
	}
	return null
})

export const newReportExportInitiatedSelector = createSelector(mainSelector, (state) => {
	return state.newReportExportIsInitiated
})