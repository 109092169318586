import * as auth from '../../../apis/auth'
import * as terms from '../../../apis/terms'
import history from '../../../utilities/history'
import * as cookies from '../../../utilities/cookies'
import { hideModal, startSaving, stopSaving } from '../core'
import { checkSubscriptionValidity } from '../subscriptions'

const SIGN_IN_REQUESTED = 'SIGN_IN_REQUESTED'
const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
const SIGN_IN_FAILED = 'SIGN_IN_FAILED'

export const signIn = (email, password, rememberMe, returnUrl, language) => (dispatch) => {
	dispatch({ type: SIGN_IN_REQUESTED, data: {} })
	return auth.signIn(email, password, rememberMe, language).then((response) => {
		if (response && response.isSuccessful) {
			if (returnUrl) {
				history.push(returnUrl)
			} else {
				history.push('/dashboard')
			}
			return dispatch({ type: SIGN_IN_SUCCESS, data: response.user })
		} else {
			if (response) {
                switch (response.errorCode) {
					case 1:
                        dispatch({
                            type: 'SET_SNACKBAR_MESSAGE',
                            data: {
                                message: 'app.forgotPass.unconfirmedError',
                                isSuccess: false
                            }
                        })
						history.push(`/auth/register/confirmation?email=${encodeURIComponent(email)}`)
						return;
					case 22:
                        return dispatch({ type: 'PHONE_VERIFICATION_CODE_SUCCESS', data: response.verifyPhone });
						break;
                }
			}

			cookies.remove('user')

			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.login.error', isSuccess: false }
			})
		}
	})
}

const COMPLETE_SIGN_IN_PHONE_VERIFICATION_REQUESTED = 'COMPLETE_SIGN_IN_PHONE_VERIFICATION_REQUESTED'
const COMPLETE_SIGN_IN_PHONE_VERIFICATION_SUCCESS = 'COMPLETE_SIGN_IN_PHONE_VERIFICATION_SUCCESS'
const COMPLETE_SIGN_IN_PHONE_VERIFICATION_FAILED = 'COMPLETE_SIGN_IN_PHONE_VERIFICATION_FAILED'

export const completeSignInPhoneVerification = (code, returnUrl) => (dispatch, getState) => {
    dispatch({ type: COMPLETE_SIGN_IN_PHONE_VERIFICATION_REQUESTED, data: {} })

    dispatch(startSaving());

    let state = getState();

	return auth.completePhoneVerification(code, state.user.verifyPhoneMask.rawData)
        .then((response) => {

            dispatch(stopSaving());

            if (response && response.isSuccessful) {

				if (returnUrl) {
                    history.push(returnUrl)
                } else {
                    history.push('/dashboard')
				}

                return dispatch({ type: SIGN_IN_SUCCESS, data: response.user })

            } else {

                if (response) {
                    switch (response.errorCode) {
                    case 23:
                        dispatch({
                            type: 'SET_SNACKBAR_MESSAGE',
                            data: {
                                message: `app.security.mfa.failed.invalidCode`,
                                isSuccess: false
                            }
                        })
                        break;
                    }
                }

                return dispatch({ type: COMPLETE_SIGN_IN_PHONE_VERIFICATION_FAILED, data: {} })
            }
        })
}

const SIGN_OUT_REQUESTED = 'SIGN_OUT_REQUESTED'
const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS'
//const SIGN_OUT_FAILED = 'SIGN_OUT_FAILED'

export const signOut = (returnUrl, doNotRedirect) => (dispatch) => {
	dispatch({ type: SIGN_OUT_REQUESTED, data: {} })
	localStorage.removeItem('user')
	return auth.signOut().then((response) => {
		if (!doNotRedirect)
            history.push(`/auth/login${returnUrl ? '?returnUrl=' + returnUrl : ''}`)
		return dispatch({ type: SIGN_OUT_SUCCESS, data: {} })
	})
}

const RESET_FAILED_AUTHENTICATION_REQUESTED = 'RESET_FAILED_AUTHENTICATION_REQUESTED'
const RESET_FAILED_AUTHENTICATION_SUCCESS = 'RESET_FAILED_AUTHENTICATION_SUCCESS'

export const resetFailedLogin = (email, password) => (dispatch) => {
	dispatch({ type: RESET_FAILED_AUTHENTICATION_REQUESTED, data: {} })

	return
}

const CONFIRM_FORGOT_PASSWORD_EMAIL_REQUESTED = 'CONFIRM_FORGOT_PASSWORD_EMAIL_REQUESTED'
const CONFIRM_FORGOT_PASSWORD_EMAIL_SUCCESS = 'CONFIRM_FORGOT_PASSWORD_EMAIL_SUCCESS'
const CONFIRM_FORGOT_PASSWORD_EMAIL_FAILED = 'CONFIRM_FORGOT_PASSWORD_EMAIL_FAILED'

export const confirmForgotPasswordEmail = (email, code, password) => (dispatch) => {
	dispatch({ type: CONFIRM_FORGOT_PASSWORD_EMAIL_REQUESTED, data: {} })
	return auth.confirmForgotPassword(email, code, password).then((response) => {
		if (response && response.ok) {
			history.push('/auth/login')
			return dispatch({
				type: CONFIRM_FORGOT_PASSWORD_EMAIL_SUCCESS,
				data: { confirmedSuccessfully: true }
			})
		} else {
			return dispatch({
				type: CONFIRM_FORGOT_PASSWORD_EMAIL_FAILED,
				data: { confirmedSuccessfully: false }
			})
		}
	})
}

const FORGOT_PASSWORD_EMAIL_REQUESTED = 'FORGOT_PASSWORD_EMAIL_REQUESTED'
const FORGOT_PASSWORD_EMAIL_SUCCESS = 'FORGOT_PASSWORD_EMAIL_SUCCESS'
const FORGOT_PASSWORD_EMAIL_FAILED = 'FORGOT_PASSWORD_EMAIL_FAILED'

export const requestForgotPasswordEmail = (email) => (dispatch) => {
	let trimmedEmail = email?.trim()
	dispatch({ type: FORGOT_PASSWORD_EMAIL_REQUESTED, data: {} })
	return auth
		.requestForgotPasswordEmail(trimmedEmail)
		.then((response) => {
			if (!response || !response.ok) {
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: { message: 'app.errorMessages.2', isSuccess: false }
				})
			} else return response.json()
		})
		.then((result) => {
			if (result && result.isSuccessful) {
				history.push('/auth/confirmpassword?email=' + encodeURIComponent(trimmedEmail))
				return dispatch({
					type: FORGOT_PASSWORD_EMAIL_SUCCESS,
					data: { requestedSuccessfully: true }
				})
			} else {
				if (result.errorCode === 1) {
					dispatch({
						type: 'SET_SNACKBAR_MESSAGE',
						data: {
							message: 'app.forgotPass.unconfirmedError',
							isSuccess: false
						}
					})
					history.push(`/auth/register/confirmation?email=${encodeURIComponent(trimmedEmail)}`)
					return
				} else if (result.errorCode === 7) {
					dispatch({
						type: 'SET_SNACKBAR_MESSAGE',
						data: {
							message: 'app.forgotPass.UserDoesNotExist',
							isSuccess: false
						}
					})
				} else {
					dispatch({
						type: 'SET_SNACKBAR_MESSAGE',
						data: {
							message: 'app.forgotPass.error',
							isSuccess: false
						}
					})
				}
			}
		})
}

const REGISTER_USER_REQUESTED = 'REGISTER_USER_REQUESTED'
const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS'
const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED'

export const registerUser = (user, emailVerified) => (dispatch) => {
	dispatch({ type: REGISTER_USER_REQUESTED, data: {} })
	if (emailVerified) {
		return auth.registerUserEmailVerified(user).then((response) => {
			if (response && response.isSuccessful === true) {
				history.push(`/dashboard/invitation`)
				return dispatch({ type: REGISTER_USER_SUCCESS, data: {} })
			} else {
				dispatch({ type: REGISTER_USER_FAILED, data: {} })
				if (response.errorCode === 6) {
					return dispatch({
						type: 'SET_SNACKBAR_MESSAGE',
						data: {
							message: 'app.registration.duplicateUser',
							isSuccess: false
						}
					})
				}
				return dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: {
						message: 'app.registration.failToRegister',
						isSuccess: false
					}
				})
			}
		})
	}
	return auth.registerUser(user).then((response) => {
		if (response && response.isSuccessful === true) {
			history.push(`/auth/register/confirmation?email=${encodeURIComponent(user.email)}`)
			if(response.invitationError) {
				if (response.errorCode === 18) {
					dispatch({
						type: 'SET_SNACKBAR_MESSAGE',
						data: {
							message: `app.circle.failedToInviteNoSubscriptions`,
							isSuccess: false
						}
					})
				} else {
					dispatch({
						type: 'SET_SNACKBAR_MESSAGE',
						data: {
							message: response && response.errorCode === 15
								? 'app.circle.failedToInviteMemberNTO'
								: `app.circle.failedToInviteMember`,
							isSuccess: false
						}
					})
				}
			}
			return dispatch({ type: REGISTER_USER_SUCCESS, data: {} })
		} else {
			if (response.errorCode === 6) {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: {
						message: 'app.registration.duplicateUser',
						isSuccess: false
					}
				})
			}
			else {
				dispatch({
					type: 'SET_SNACKBAR_MESSAGE',
					data: {
						message: 'app.registration.failToRegister',
						isSuccess: false
					}
				})
			}
			return dispatch({ type: REGISTER_USER_FAILED, data: {} })
		}
	})
}

const CONFIRM_REGISTRATION_REQUESTED = 'CONFIRM_REGISTRATION_REQUESTED'
const CONFIRM_REGISTRATION_SUCCESS = 'CONFIRM_REGISTRATION_SUCCESS'
const CONFIRM_REGISTRATION_FAILED = 'CONFIRM_REGISTRATION_FAILED'

export const confirmUser = (code, email, history) => (dispatch) => {
	dispatch({ type: CONFIRM_REGISTRATION_REQUESTED, data: {} })

	return auth.confirmUser(email, code).then((response) => {
		if (response && response.isSuccessful === true) {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.onboarding.confirmSuccessMessage',
					isSuccess: true
				}
			})
			history.push('/')
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.onboarding.confirmFailMessage',
					isSuccess: false
				}
			})
		}
	})
}

export const redirectToUrl = (url) => (dispatch) => {
	history.push(url)
}

const RESEND_CONFIRMATION_CODE_REQUESTED = 'RESEND_CONFIRMATION_CODE_REQUESTED'
const RESEND_CONFIRMATION_CODE_SUCCESS = 'RESEND_CONFIRMATION_CODE_SUCCESS'
const RESEND_CONFIRMATION_CODE_FAILED = 'RESEND_CONFIRMATION_CODE_FAILED'

export const resendConfirmationCode = (email, successMessage, failMessage) => (dispatch) => {
	dispatch({ type: RESEND_CONFIRMATION_CODE_REQUESTED, data: {} })

	var t = encodeURIComponent(email)
	return auth.resendConfirmationCode(encodeURIComponent(email.trim())).then((response) => {
		if (response && response.ok === true) {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: successMessage, isSuccess: true }
			})
			return dispatch({
				type: RESEND_CONFIRMATION_CODE_SUCCESS,
				data: {}
			})
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: failMessage, isSuccess: true }
			})
			return dispatch({
				type: RESEND_CONFIRMATION_CODE_FAILED,
				data: {}
			})
		}
	})
}

const CHANGE_PASSWORD_REQUESTED = 'CHANGE_PASSWORD_REQUESTED'
const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED'

export const changePassword = (email, oldPassword, newPassword) => (dispatch) => {
	dispatch({ type: CHANGE_PASSWORD_REQUESTED, data: {} })

	return auth.changePassword(email, oldPassword, newPassword).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(hideModal())
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.changePassword.success', isSuccess: true }
			})
			return dispatch({ type: CHANGE_PASSWORD_SUCCESS, data: response.user })
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: { message: 'app.general.failed', isSuccess: false }
			})
		}
	})
}

const REFRESH_TOKEN_REQUESTED = 'REFRESH_TOKEN_REQUESTED'
const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS'
const REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED'

export const refreshToken = () => (dispatch) => {
    dispatch({ type: REFRESH_TOKEN_REQUESTED, data: {} })

	return auth.refreshToken().then((response) => {
        if (response && response.isSuccessful) {
            return dispatch({ type: REFRESH_TOKEN_SUCCESS })
		} else {
			return dispatch({ type: REFRESH_TOKEN_FAILED })

            return dispatch({
                type: 'SET_SNACKBAR_MESSAGE',
                data: { message: 'app.general.failed', isSuccess: false }
            })
        }
    })
}


const CHECK_SUBSCRIPTION_UPDATES_REQUESTED = 'CHECK_SUBSCRIPTION_UPDATES_REQUESTED'
const CHECK_SUBSCRIPTION_UPDATES_SUCCESS = 'CHECK_SUBSCRIPTION_UPDATES_SUCCESS'
const CHECK_SUBSCRIPTION_UPDATES_FAILED = 'CHECK_SUBSCRIPTION_UPDATES_FAILED'

export const checkSubscriptionUpdates = () => (dispatch) => {
    dispatch({ type: CHECK_SUBSCRIPTION_UPDATES_REQUESTED, data: {} })

	return auth.checkSubscriptionUpdates().then((response) => {
		dispatch({ type: CHECK_SUBSCRIPTION_UPDATES_SUCCESS })
        if (response && response.isSuccessful) {
            dispatch(checkSubscriptionValidity())
			return dispatch({ type: REFRESH_TOKEN_SUCCESS, data: {} })
		}
    })
}

const initial = {
	confirmed: false,
	isTokenRefreshing: false,
	error: false,
	failedLogin: false,
	requestedSuccessfully: null,
	requestPasswordStatus: null,
	user: null,
	confirmationResent: false,
	isRegistering: false
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case SIGN_IN_SUCCESS:
			return { ...state, user: action.data }
		case SIGN_IN_FAILED:
			return { ...state, failedLogin: true }
		case REGISTER_USER_REQUESTED:
			return { ...state, isRegistering: true }
		case REGISTER_USER_SUCCESS:
			return { ...state, isRegistering: false }
		case REGISTER_USER_FAILED:
			return { ...state, error: true, isRegistering: false }
		case CONFIRM_REGISTRATION_SUCCESS:
			return { ...state, confirmationResent: true }
		case CONFIRM_REGISTRATION_SUCCESS:
			return { ...state, confirmed: true }
		case RESET_FAILED_AUTHENTICATION_REQUESTED:
			return { ...state, failedLogin: false }
        case FORGOT_PASSWORD_EMAIL_SUCCESS:
			return { ...state }
		case 'SIGN_OUT_REQUESTED':
			return initial;
		case REFRESH_TOKEN_REQUESTED:
			return { ...state, isTokenRefreshing: true }
		case REFRESH_TOKEN_SUCCESS:
			return { ...state, isTokenRefreshing: false }
		case REFRESH_TOKEN_FAILED:
			return { ...state, isTokenRefreshing: false }
        default:
			return { ...state }
	}
}

export const confirmedSelector = (state) => {
	return state.auth.confirmed
}

export const errorSelector = (state) => {
	return state.auth.error
}

export const userSelector = (state) => {
	return state.auth.user
}

export const isTokenRefreshingSelector = (state) => {
	return state.auth.isTokenRefreshing
}

export const isRegisteringSelector = (state) => {
	return state.auth.isRegistering
}
