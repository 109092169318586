import * as users from '../../../apis/user'
import * as invitations from '../../../apis/userInvitation'
import { createSelector } from 'reselect'
import * as cookies from '../../../utilities/cookies'
import { hideModal, startSaving, stopSaving } from '../core'
import { setSnackbarMessage } from '../../actions'
import * as circleInvitation from '../../../apis/circleInvitation'
import { getUser } from '../../../utilities/userUtilities'
import history from '../../../utilities/history'
import VerifyPhoneNumber from '../../../components/VerifyPhoneNumber'

const FETCH_CURRENT_USER_REQUESTED = 'FETCH_CURRENT_USER_REQUESTED'
const FETCH_CURRENT_USER_SUCCESS = 'FETCH_CURRENT_USER_SUCCESS'
const FETCH_CURRENT_USER_FAILED = 'FETCH_CURRENT_USER_FAILED'

export const getCurrentUserDetails = () => (dispatch) => {
	return users.fetchCurrentUser().then((user) => {
		if (user) {
			return dispatch({ type: FETCH_CURRENT_USER_SUCCESS, data: user })
		} else {
			return dispatch({ type: FETCH_CURRENT_USER_FAILED, data: {} })
		}
	})
	return
}

const FETCH_TASKS_REQUESTED = 'FETCH_TASKS_REQUESTED'
const FETCH_TASKS_SUCCESS = 'FETCH_TASKS_SUCCESS'
const FETCH_TASKS_FAILED = 'FETCH_TASKS_FAILED'

export const fetchTasks = () => (dispatch) => {
	dispatch({ type: FETCH_TASKS_REQUESTED, data: {} })

	return users.fetchTasks().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: FETCH_TASKS_SUCCESS,
				data: {
					items: response.alerts,
					field: 'name',
					isDescending: false
				}
			})
		} else {
			return dispatch({ type: FETCH_TASKS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_USER_BY_EMAIL_REQUESTED = 'FETCH_USER_BY_EMAIL_REQUESTED'
const FETCH_USER_BY_EMAIL_SUCCESS = 'FETCH_USER_BY_EMAIL_SUCCESS'
const FETCH_USER_BY_EMAIL_FAILED = 'FETCH_USER_BY_EMAIL_FAILED'

export const getUserDetailsByEmail = (email) => (dispatch) => {
	dispatch({ type: FETCH_USER_BY_EMAIL_REQUESTED, data: {} })
	return users.fetchUserByEmail(email).then((user) => {
		if (user) {
			return dispatch({ type: FETCH_USER_BY_EMAIL_SUCCESS, data: user })
		} else {
			return dispatch({ type: FETCH_USER_BY_EMAIL_FAILED, data: {} })
		}
	})
	return
}

const FETCH_FULL_USER_REQUESTED = 'FETCH_FULL_USER_REQUESTED'
const FETCH_FULL_USER_SUCCESS = 'FETCH_FULL_USER_SUCCESS'
const FETCH_FULL_USER_FAILED = 'FETCH_FULL_USER_FAILED'

export const getFullUserById = () => (dispatch) => {
	dispatch({ type: FETCH_FULL_USER_REQUESTED, data: {} })
	return users.fetchFullUserById().then((user) => {
		if (user) {
			return dispatch({ type: FETCH_FULL_USER_SUCCESS, data: user })
		} else {
			return dispatch({ type: FETCH_FULL_USER_FAILED, data: {} })
		}
	})
	return
}

const FETCH_USER_STATISTICS_REQUESTED = 'FETCH_USER_STATISTICS_REQUESTED'
const FETCH_USER_STATISTICS_SUCCESS = 'FETCH_USER_STATISTICS_SUCCESS'
const FETCH_USER_STATISTICS_FAILED = 'FETCH_USER_STATISTICS_FAILED'

export const getUserStatistics = () => (dispatch) => {
	dispatch({ type: FETCH_USER_STATISTICS_REQUESTED, data: {} })
	return users.fetchUserStats().then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({ type: FETCH_USER_STATISTICS_SUCCESS, data: response })
		} else {
			return dispatch({ type: FETCH_USER_STATISTICS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_USER_NOTIFICATION_SETTINGS_REQUESTED = 'FETCH_USER_NOTIFICATION_SETTINGS_REQUESTED'
const FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS = 'FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS'
const FETCH_USER_NOTIFICATION_SETTINGS_FAILED = 'FETCH_USER_NOTIFICATION_SETTINGS_FAILED'

export const getUserNotificationSettingsById = () => (dispatch) => {
	dispatch({ type: FETCH_USER_NOTIFICATION_SETTINGS_REQUESTED, data: {} })
	return users.fetchUserNotificationSettingsById().then((settings) => {
		if (settings) {
			return dispatch({
				type: FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS,
				data: settings
			})
		} else {
			return dispatch({
				type: FETCH_USER_NOTIFICATION_SETTINGS_FAILED,
				data: {}
			})
		}
	})
	return
}

const UPDATE_USER_NOTIFICATION_SETTINGS_REQUESTED = 'UPDATE_USER_NOTIFICATION_SETTINGS_REQUESTED'
const UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS = 'UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS'
const UPDATE_USER_NOTIFICATION_SETTINGS_FAILED = 'UPDATE_USER_NOTIFICATION_SETTINGS_FAILED'

export const updateUserNotificationSettingsById = (id, outlier, protocol, invitiation) => (dispatch) => {
	dispatch({ type: UPDATE_USER_NOTIFICATION_SETTINGS_REQUESTED, data: {} })
	return users.updateUserNotificationSettingsById(id, outlier, invitiation, protocol).then((settings) => {
		if (settings) {
			return dispatch({
				type: UPDATE_USER_NOTIFICATION_SETTINGS_SUCCESS,
				data: settings
			})
		} else {
			return dispatch({
				type: UPDATE_USER_NOTIFICATION_SETTINGS_FAILED,
				data: {}
			})
		}
	})
	return
}

const UPDATE_USER_DETAILS_REQUESTED = 'UPDATE_USER_DETAILS_REQUESTED'
const UPDATE_USER_DETAILS_SUCCESS = 'UPDATE_USER_DETAILS_SUCCESS'
const UPDATE_USER_DETAILS_FAILED = 'UPDATE_USER_DETAILS_FAILED'

export const updateUserDetailsById = (user) => (dispatch) => {
	dispatch({ type: UPDATE_USER_DETAILS_REQUESTED, data: {} })
	return users.updateUserDetailsById(user).then((user) => {
		if (user) {
			dispatch(hideModal())

            window.location.reload();

			return dispatch({ type: UPDATE_USER_DETAILS_SUCCESS, data: user })
		} else {
			return dispatch({ type: UPDATE_USER_DETAILS_FAILED, data: {} })
		}
	})
	return
}

const FETCH_MFA_STATE_REQUESTED = 'FETCH_MFA_STATE_REQUESTED'
const FETCH_MFA_STATE_SUCCESS = 'FETCH_MFA_STATE_SUCCESS'
const FETCH_MFA_STATE_FAILED = 'FETCH_MFA_STATE_FAILED'

export const fetchMFAState = () => (dispatch) => {
	dispatch({ type: FETCH_MFA_STATE_REQUESTED, data: {} })

	return users.fetchMFAState().then((response) => {
        if (response && response.isSuccessful) {
            return dispatch({ type: FETCH_MFA_STATE_SUCCESS, data: response.data })
        } else {
            return dispatch({ type: FETCH_MFA_STATE_FAILED, data: {} })
        }
    })
}

const PHONE_VERIFICATION_CODE_REQUESTED = 'PHONE_VERIFICATION_CODE_REQUESTED'
const PHONE_VERIFICATION_CODE_SUCCESS = 'PHONE_VERIFICATION_CODE_SUCCESS'
const PHONE_VERIFICATION_CODE_FAILED = 'PHONE_VERIFICATION_CODE_FAILED'

export const requestPhoneVerificationCode = (phoneCountryCode, phoneNumber, resend) => (dispatch) => {
	dispatch({ type: PHONE_VERIFICATION_CODE_REQUESTED, data: {} })

    dispatch(startSaving());

	return users.requestPhoneVerificationCode(phoneCountryCode, phoneNumber)
        .then((response) => {

            dispatch(stopSaving());

            if (response && response.isSuccessful) {

				dispatch({ type: PHONE_VERIFICATION_CODE_SUCCESS, data: response.data });

                if (resend) {
                    dispatch({
                        type: 'SET_SNACKBAR_MESSAGE',
                        data: {
							message: `app.security.mfa.resend.success`,
                            isSuccess: true
                        }
                    })
                }
            } else {

				if (response) {
					switch (response.errorCode) {
						case 8:
							dispatch({
								type: 'SET_SNACKBAR_MESSAGE',
								data: {
									message: `app.security.mfa.failed.invalidNumber`,
									isSuccess: false
								}
							})
							break;
						case 24:
							dispatch({
								type: 'SET_SNACKBAR_MESSAGE',
								data: {
									message: `app.security.mfa.failed.attemptLimitExceeded`,
									isSuccess: false
								}
							})
							break;
                        case 25:
                            dispatch({
                                type: 'SET_SNACKBAR_MESSAGE',
                                data: {
									message: `app.security.mfa.failed.phoneIsAlreadyInUse`,
                                    isSuccess: false
                                }
                            })
                            break;
						default:
                            dispatch({
                                type: 'SET_SNACKBAR_MESSAGE',
                                data: {
									message: `app.security.mfa.failed.sendCodeError`,
                                    isSuccess: false
                                }
                            })
					}
				}

				dispatch({ type: PHONE_VERIFICATION_CODE_FAILED, data: {} })
            }
        })
}

const COMPLETE_PHONE_VERIFICATION_REQUESTED = 'COMPLETE_PHONE_VERIFICATION_REQUESTED'
const COMPLETE_PHONE_VERIFICATION_SUCCESS = 'COMPLETE_PHONE_VERIFICATION_SUCCESS'
const COMPLETE_PHONE_VERIFICATION_FAILED = 'COMPLETE_PHONE_VERIFICATION_FAILED'

export const completePhoneVerification = (code, mode) => (dispatch, getState) => {
    dispatch({ type: COMPLETE_PHONE_VERIFICATION_REQUESTED, data: {} })

    dispatch(startSaving());

    return users.completePhoneVerification(code)
        .then((response) => {

            dispatch(stopSaving());

			if (response && response.isSuccessful) {
                
                dispatch({ type: COMPLETE_PHONE_VERIFICATION_SUCCESS, data: response.data });

                dispatch({
                    type: 'SET_SNACKBAR_MESSAGE',
                    data: {
						message: `app.security.mfa.setup.success.phoneVerified`,
                        isSuccess: true
                    }
				})

                let state = getState()


				if (state && state.user) {

					if (['attach', 'verify'].includes(mode) && state.user.mFAState) {

                        if (state.user.isUserMFAStateLoading) {
                            dispatch(updateMFAState(1, !state.user.mFAState.user2FAEnabled, response.data, mode))
                        }

                        if (state.user.isPatientMFAStateLoading) {
                            dispatch(updateMFAState(2, !state.user.mFAState.patient2FAEnabled, response.data, mode))
                        }
					}
					else if (mode === 'attach-cookie')
                    {
                        dispatch(updateMFAState(1, true, response.data))
                    }
                }

                return dispatch(hideModal())

			} else {

				if (response) {
					switch (response.errorCode) {
						case 23:
							dispatch({
								type: 'SET_SNACKBAR_MESSAGE',
								data: {
									message: `app.security.mfa.failed.invalidCode`,
									isSuccess: false
								}
							})
							break;
					}
				}

                return dispatch({ type: COMPLETE_PHONE_VERIFICATION_FAILED, data: {} })
            }
        })
}

const RESET_PHONE_VERIFICATION_CODE_REQUESTED = 'RESET_PHONE_VERIFICATION_CODE_REQUESTED'

export const resetPhoneVerificationCode = () => (dispatch) => {
    return dispatch({ type: RESET_PHONE_VERIFICATION_CODE_REQUESTED, data: {} });
}

const UPDATE_MFA_USER_STATE_REQUESTED = 'UPDATE_MFA_USER_STATE_REQUESTED'
const UPDATE_MFA_PATIENT_STATE_REQUESTED = 'UPDATE_MFA_PATIENT_STATE_REQUESTED'
const UPDATE_MFA_STATE_SUCCESS = 'UPDATE_MFA_STATE_SUCCESS'
const UPDATE_MFA_STATE_FAILED = 'UPDATE_MFA_STATE_FAILED'

export const updateMFAState = (type, state, mfaSign, mode) => (dispatch) => {
	dispatch({ type: type === 1 ? UPDATE_MFA_USER_STATE_REQUESTED : UPDATE_MFA_PATIENT_STATE_REQUESTED, data: {} })

	return users.updateMFAState(type, state, mfaSign)
		.then((response) => {

            if (response && response.isSuccessful) {

                if(['attach', 'verify'].includes(mode))
                {
                	dispatch(getTeamMembers())
                }

                return dispatch({ type: UPDATE_MFA_STATE_SUCCESS, data: response.data });
			}
			else
            {
				//dispatch({ type: UPDATE_MFA_STATE_FAILED, data: {} })

				if (response) {
					let onCloseHandler = { type: UPDATE_MFA_STATE_FAILED, data: {} }

					switch (response.errorCode) {
						case 2:
							dispatch({ type: UPDATE_MFA_STATE_FAILED, data: {} })

							return dispatch({
								type: 'SET_SNACKBAR_MESSAGE',
								data: {
									message: `app.general.failed`,
									isSuccess: false
								}
							})
						case 21:
                            return dispatch({
                                type: 'SHOW_MODAL',
                                data: {
                                    component: VerifyPhoneNumber,
                                    options: {
                                        mode: 'attach',
                                        modalContainerStyle: { fullWidth: false }
                                    },
                                    show: true,
									canClose: true,
									onClose: onCloseHandler
                                }
                            })
                        case 22:
							return dispatch({
								type: 'SHOW_MODAL',
								data: {
									component: VerifyPhoneNumber,
                                    options: {
										mode: 'verify',
										modalContainerStyle: { fullWidth: false }
									},
									show: true,
									canClose: true,
                                    onClose: onCloseHandler
								}
							})
					}
				}
            }
        })
}

const SET_USER_PATHOLOGIES_AND_PROMS_REQUESTED = 'SET_USER_PATHOLOGIES_AND_PROMS_REQUESTED'
const SET_USER_PATHOLOGIES_AND_PROMS_SUCCESS = 'SET_USER_PATHOLOGIES_AND_PROMS_SUCCESS'
const SET_USER_PATHOLOGIES_AND_PROMS_FAILED = 'SET_USER_PATHOLOGIES_AND_PROMS_FAILED'

export const setInitialUserPathologiesAndProms = (pathology, proms, type) => (dispatch) => {
	dispatch({ type: SET_USER_PATHOLOGIES_AND_PROMS_REQUESTED, data: {} })

	let user = cookies.get('user')

	return users.setPathologiesAndProms(user.id, pathology, proms, true, type).then((response) => {
		if (response) {
			dispatch(getFullUserById())
			return dispatch(hideModal())
		} else {
			dispatch(setSnackbarMessage('Unable to edit profile. Please enter all required fields.'))
			return dispatch({
				type: SET_USER_PATHOLOGIES_AND_PROMS_FAILED,
				data: {}
			})
		}
	})
	return
}

const IGNORE_USER_PATHOLOGIES_AND_PROMS_REQUESTED = 'IGNORE_USER_PATHOLOGIES_AND_PROMS_REQUESTED'
const IGNORE_USER_PATHOLOGIES_AND_PROMS_SUCCESS = 'IGNORE_USER_PATHOLOGIES_AND_PROMS_SUCCESS'
const IGNORE_USER_PATHOLOGIES_AND_PROMS_FAILED = 'IGNORE_USER_PATHOLOGIES_AND_PROMS_FAILED'

export const ignoreInitialUserPathologiesAndProms = () => (dispatch) => {
	dispatch({ type: IGNORE_USER_PATHOLOGIES_AND_PROMS_REQUESTED, data: {} })

	let user = cookies.get('user')
	return users.ignoreInitialPathologiesAndProms(user.id).then((response) => {
		if (response) {
			dispatch(getFullUserById())
			return dispatch(hideModal())
		} else {
			return dispatch({
				type: IGNORE_USER_PATHOLOGIES_AND_PROMS_FAILED,
				data: {}
			})
		}
	})
	return
}

const SET_USER_LANGUAGE_REQUESTED = 'SET_USER_LANGUAGE_REQUESTED'
const SET_USER_LANGUAGE_SUCCESS = 'SET_USER_LANGUAGE_SUCCESS'
const SET_USER_LANGUAGE_FAILED = 'SET_USER_LANGUAGE_FAILED'

export const setUserLanguage = (language) => (dispatch) => {
	dispatch({ type: SET_USER_LANGUAGE_REQUESTED, data: {} })
	let user = cookies.get('user')
	return users.updateUserLanguage(user.id, language).then((response) => {
		if (response) {
			dispatch(getFullUserById())
		} else {
			return dispatch({ type: SET_USER_LANGUAGE_FAILED, data: {} })
		}
	})
	return
}

const SET_USER_INVITATION_MEMBER_REQUESTED = 'SET_USER_INVITATION_MEMBER_REQUESTED'
const SET_USER_INVITATION_MEMBER_SUCCESS = 'SET_USER_INVITATION_MEMBER_SUCCESS'
const SET_USER_INVITATION_MEMBER_FAILED = 'SET_USER_INVITATION_MEMBER_FAILED'

export const inviteUserMember = (email, languageId) => (dispatch) => {
	dispatch({ type: SET_USER_INVITATION_MEMBER_REQUESTED, data: {} })

	let user = cookies.get('user')

	return invitations.inviteUserMember(user.id, email, languageId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(getTeamMembers())
			dispatch({
				type: SET_USER_INVITATION_MEMBER_SUCCESS,
				data: response
			})
			dispatch(hideModal())
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: `app.general.inviteSuccessful`,
					isSuccess: true
				}
			})
		} else {
			dispatch({
				type: SET_USER_INVITATION_MEMBER_FAILED,
				data: response
			})
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: `app.errorMessages.${response.errorCode}`,
					isSuccess: false
				}
			})
		}
	})
	return
}

const RESEND_USER_INVITATION_REQUESTED = 'RESEND_USER_INVITATION_REQUESTED'
const RESEND_USER_INVITATION_SUCCESS = 'RESEND_USER_INVITATION_SUCCESS'
const RESEND_USER_INVITATION_FAILED = 'RESEND_USER_INVITATION_FAILED'

export const resendInvitationEmail = (invitationId) => (dispatch) => {
	dispatch({ type: RESEND_USER_INVITATION_REQUESTED, data: {} })

	let user = cookies.get('user')

	return invitations.resendInvitation(invitationId).then((response) => {
		if (response && response.isSuccessful) {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.userProfile.teamMembeResendInvitateSuccess',
					isSuccess: true
				}
			})
		} else {
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.userProfile.teamMembeResendInvitateFailed',
					isSuccess: false
				}
			})
		}
	})
	return
}

const UPDATE_USER_INVITATION_MEMBER_REQUESTED = 'SET_USER_INVITATION_MEMBER_REQUESTED'
const UPDATE_USER_INVITATION_MEMBER_SUCCESS = 'SET_USER_INVITATION_MEMBER_SUCCESS'
const UPDATE_USER_INVITATION_MEMBER_FAILED = 'SET_USER_INVITATION_MEMBER_FAILED'

export const updateInviteUserMember = (optionSelected) => (dispatch) => {
	dispatch({ type: UPDATE_USER_INVITATION_MEMBER_REQUESTED, data: {} })

	let user = cookies.get('user')

	return invitations.updateInviteUserMember(user.id, user.email, optionSelected).then((response) => {
		if (response) {
			dispatch({
				type: UPDATE_USER_INVITATION_MEMBER_SUCCESS,
				data: {}
			})

			return dispatch(hideModal())
		} else {
			return dispatch({
				type: UPDATE_USER_INVITATION_MEMBER_FAILED,
				data: {}
			})
		}
	})
	return
}

const FETCH_USER_INVITATIONS_REQUESTED = 'FETCH_USER_INVITATIONS_REQUESTED'
const FETCH_USER_INVITATIONS_SUCCESS = 'FETCH_USER_INVITATIONS_SUCCESS'
const FETCH_USER_INVITATIONS_FAILED = 'FETCH_USER_INVITATIONS_FAILED'

export const getTeamMembers = () => (dispatch) => {
	dispatch({ type: FETCH_USER_INVITATIONS_REQUESTED, data: {} })

	return users.fetchTeamMembers().then((memberCollection) => {
		if (memberCollection && memberCollection.isSuccessful && memberCollection.members) {
			return dispatch({
				type: FETCH_USER_INVITATIONS_SUCCESS,
				data: memberCollection.members
			})
		} else {
			return dispatch({ type: FETCH_USER_INVITATIONS_FAILED, data: {} })
		}
	})
	return
}

const REMOVE_USER_INVITATION_MEMBER_REQUESTED = 'REMOVE_USER_INVITATION_MEMBER_REQUESTED'
const REMOVE_USER_INVITATION_MEMBER_SUCCESS = 'REMOVE_USER_INVITATION_MEMBER_SUCCESS'
const REMOVE_USER_INVITATION_MEMBER_FAILED = 'REMOVE_USER_INVITATION_MEMBER_FAILED'

export const deleteUserMember = (id, invitationId) => (dispatch) => {
	dispatch({ type: REMOVE_USER_INVITATION_MEMBER_REQUESTED, data: {} })
	return invitations.deleteUserMember(id, invitationId).then((response) => {
		if (response && response.isSuccessful) {
			dispatch(getTeamMembers())

			dispatch({
				type: REMOVE_USER_INVITATION_MEMBER_SUCCESS,
				data: response
			})
			return dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.userProfile.teamMemberRemoveInviteSuccess',
					isSuccess: true
				}
			})
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.userProfile.teamMemberRemoveInviteFailed',
					isSuccess: false
				}
			})
			return dispatch({
				type: REMOVE_USER_INVITATION_MEMBER_FAILED,
				data: {}
			})
		}
	})
	return
}

const UPLOAD_USER_IMAGE_REQUESTED = 'UPLOAD_USER_IMAGE_REQUESTED'
const UPLOAD_USER_IMAGE_SUCCESS = 'UPLOAD_USER_IMAGE_SUCCESS'
const UPLOAD_USER_IMAGE_FAILED = 'UPLOAD_USER_IMAGE_FAILED'

export const setPhoto = (entity, id, photo, onImageUpdated) => (dispatch) => {
	dispatch({ type: UPLOAD_USER_IMAGE_REQUESTED, data: {} })
	let formData = new FormData()
	formData.append('files', photo)
	return users.setUserImage(entity, id, formData).then((response) => {
		if (response) {
			if (onImageUpdated) dispatch(onImageUpdated(id))
			return dispatch({ type: UPLOAD_USER_IMAGE_SUCCESS, data: response })
		} else {
			return dispatch({ type: UPLOAD_USER_IMAGE_FAILED, data: {} })
		}
	})
	return
}

const RESET_USER_IMAGE_REQUESTED = 'RESET_USER_IMAGE_REQUESTED'
const RESET_USER_IMAGE_SUCCESS = 'RESET_USER_IMAGE_SUCCESS'
const RESET_USER_IMAGE_FAILED = 'RESET_USER_IMAGE_FAILED'

export const resetPhoto = (entity, id, onImageUpdated) => (dispatch) => {
	dispatch({ type: RESET_USER_IMAGE_REQUESTED, data: {} })
	return users.resetUserImage(entity, id).then((response) => {
		if (response) {
			if (onImageUpdated) dispatch(onImageUpdated(id))
			return dispatch({ type: RESET_USER_IMAGE_SUCCESS, data: response })
		} else {
			return dispatch({ type: RESET_USER_IMAGE_FAILED, data: {} })
		}
	})
	return
}

const UPLOAD_TEAM_LOGO_REQUESTED = 'UPLOAD_TEAM_LOGO_REQUESTED'
const UPLOAD_TEAM_LOGO_SUCCESS = 'UPLOAD_TEAM_LOGO_SUCCESS'
const UPLOAD_TEAM_LOGO_FAILED = 'UPLOAD_TEAM_LOGO_FAILED'

export const setTeamLogo = (id, photo, onImageUpdated) => (dispatch) => {
	dispatch({ type: UPLOAD_TEAM_LOGO_REQUESTED, data: {} })
	let formData = new FormData()
	formData.append('files', photo)
	return users.setTeamLogo(id, formData).then((response) => {
		if (response) {
			if (onImageUpdated) dispatch(onImageUpdated(id))
			return dispatch({ type: UPLOAD_TEAM_LOGO_SUCCESS, data: response })
		} else {
			return dispatch({ type: UPLOAD_TEAM_LOGO_FAILED, data: {} })
		}
	})
	return
}

const SET_TASK_SORT_REQUESTED = 'SET_TASK_SORT_REQUESTED'

export const setTaskSort = (field, isDescending) => (dispatch, state) => {
	let currentState = state()

	let data = currentState.user.tasks.sort((a, b) => {
		if (isDescending) {
			return a[field] > b[field] ? 1 : -1
		} else {
			return b[field] > a[field] ? 1 : -1
		}
	})
	return dispatch({
		type: SET_TASK_SORT_REQUESTED,
		data: { items: data, field: field, isDescending: isDescending }
	})
}

const FETCH_USER_HAS_GLOBAL_ACCESS = 'FETCH_USER_HAS_GLOBAL_ACCESS'

export const fetchHasGlobalAccess = (id) => (dispatch) => {
	dispatch({ type: FETCH_USER_HAS_GLOBAL_ACCESS, data: null })

	users.fetchHasGlobalAccess(id).then((access) => {
		return dispatch({
			type: FETCH_USER_HAS_GLOBAL_ACCESS,
			data: access
		})
	})
    return
}

const GET_USER_REGISTRATION_DATA_REQUESTED = 'GET_USER_REGISTRATION_DATA_REQUESTED'
const GET_USER_REGISTRATION_DATA_SUCCESS = 'GET_USER_REGISTRATION_DATA_SUCCESS'
const GET_USER_REGISTRATION_DATA_FAILED = 'GET_USER_REGISTRATION_DATA_FAILED'

export const getUserRegistrationData = (email) => (dispatch) => {
	dispatch({ type: GET_USER_REGISTRATION_DATA_REQUESTED, data: {} })
	return users.getUserRegistrationDataByEmail(email).then((user) => {
		if (user) {
			return dispatch({ type: GET_USER_REGISTRATION_DATA_SUCCESS, data: user })
		} else {
			return dispatch({ type: GET_USER_REGISTRATION_DATA_FAILED, data: {} })
		}
	})
	return
}

const GET_IS_USER_REGISTERED_REQUESTED = 'GET_IS_USER_REGISTERED_REQUESTED'
const GET_IS_USER_REGISTERED_SUCCESS = 'GET_IS_USER_REGISTERED_SUCCESS'
const GET_IS_USER_REGISTERED_FAILED = 'GET_IS_USER_REGISTERED_FAILED'

export const getIsUserRegistered = (email) => (dispatch) => {
	dispatch({ type: GET_IS_USER_REGISTERED_REQUESTED, data: {} })
	return users.getIsUserRegisteredByEmail(email).then((user) => {
		if (user) {
			return dispatch({ type: GET_IS_USER_REGISTERED_SUCCESS, data: user })
		} else {
			return dispatch({ type: GET_IS_USER_REGISTERED_FAILED, data: {} })
		}
	})
	return
}

const GENERATE_API_KEY_REQUESTED = 'GENERATE_API_KEY_REQUESTED'
const GENERATE_API_KEY_SUCCESS = 'GENERATE_API_KEY_SUCCESS'
const GENERATE_API_KEY_FAILED = 'GENERATE_API_KEY_FAILED'

export const generateApiKey = () => (dispatch) => {
	dispatch({ type: GENERATE_API_KEY_REQUESTED, data: {} })
	return users.generateApiKey().then((response) => {
		if (!!response?.isSuccessful) {
			var result = dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.api.generateKeySuccess',
					isSuccess: true
				}
			})

			dispatch({ type: GENERATE_API_KEY_SUCCESS, data: response.data })

			dispatch(getFullUserById())

			return result
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.api.generateKeyFailed',
					isSuccess: false
				}
			})

			return dispatch({ type: GENERATE_API_KEY_FAILED, data: {} })
		}
	})
	return
}

const CHANGE_API_KEY_STATE_REQUESTED = 'CHANGE_API_KEY_STATE_REQUESTED'
const CHANGE_API_KEY_STATE_FAILED = 'CHANGE_API_KEY_STATE_FAILED'

export const activateApiKey = () => (dispatch) => {
	dispatch({ type: CHANGE_API_KEY_STATE_REQUESTED, data: {} })
	return users.activateApiKey().then((response) => {
		if (!!response?.isSuccessful) {
			var result = dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.api.changeKeyStatusSuccess',
					isSuccess: true
				}
			})

			dispatch(getFullUserById())

			return result
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.api.activateKeyFailed',
					isSuccess: false
				}
			})

			return dispatch({ type: CHANGE_API_KEY_STATE_FAILED, data: {} })
		}
	})
	return
}

const DEACTIVATE_API_KEY_SUCCESS = 'DEACTIVATE_API_KEY_SUCCESS'

export const deactivateApiKey = () => (dispatch) => {
	dispatch({ type: CHANGE_API_KEY_STATE_REQUESTED, data: {} })
	return users.deactivateApiKey().then((response) => {
		if (!!response?.isSuccessful) {
			var result = dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.api.changeKeyStatusSuccess',
					isSuccess: true
				}
			})

			dispatch({ type: DEACTIVATE_API_KEY_SUCCESS })

			dispatch(getFullUserById())

			return result
		} else {
			dispatch({
				type: 'SET_SNACKBAR_MESSAGE',
				data: {
					message: 'app.api.deactivateKeyFailed',
					isSuccess: false
				}
			})

			return dispatch({ type: CHANGE_API_KEY_STATE_FAILED, data: {} })
		}
	})
	return
}


const initial = {
	userDetails: null,
	fullUser: null,
	stats: null,
	notificationSettings: null,
	invitation: null,
	isInviting: false,
	userInvitations: [],
	members: [],
	userByEmail: null,
	tasks: [],
	sortField: 'modifiedDateSeconds',
	isDescending: true,
	mFAState: null,
	isUserMFAStateLoading: false,
	isPatientMFAStateLoading: false,
	verifyPhoneMask: null,
	isSetupMFARequired: false,
	hasGlobalAccess: null,
	registrationData: null,
	openApiKey: null,
	openApiKeyOperationStarted: false
}

export const reducer = (state = initial, action) => {
	switch (action.type) {
		case SET_TASK_SORT_REQUESTED:
			return {
				...state,
				tasks: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case FETCH_TASKS_SUCCESS:
			return {
				...state,
				tasks: [ ...action.data.items ],
				sortField: action.data.field,
				isDescending: action.data.isDescending
			}
		case REMOVE_USER_INVITATION_MEMBER_SUCCESS:
			const id = action.data.invitationId
			return {
				...state,
				userInvitations: state.userInvitations.filter((item) => item.invitationId !== id)
			}
		case FETCH_USER_STATISTICS_SUCCESS:
			return {
				...state,
				stats: action.data
			}
		case SET_USER_INVITATION_MEMBER_REQUESTED:
			return {
				...state,
				isInviting: true
			}
		case SET_USER_INVITATION_MEMBER_FAILED:
			return {
				...state,
				isInviting: false
			}
		case SET_USER_INVITATION_MEMBER_SUCCESS:
			return {
				...state,
				isInviting: false
			}
		case UPLOAD_USER_IMAGE_SUCCESS:
			return { ...state, userDetails: action.data }
		case FETCH_USER_INVITATIONS_SUCCESS:
			return { ...state, members: action.data }
		case FETCH_USER_NOTIFICATION_SETTINGS_SUCCESS:
			return { ...state, notificationSettings: action.data }
		case FETCH_CURRENT_USER_SUCCESS:
			return { ...state, userDetails: action.data }
		case FETCH_FULL_USER_SUCCESS:
			return {
				...state,
				fullUser: action.data,
				openApiKeyOperationStarted: false
			}
		case FETCH_FULL_USER_FAILED:
			return {
				...state,
				openApiKeyOperationStarted: false
			}
		case UPDATE_USER_DETAILS_SUCCESS:
			return { ...state, userDetails: action.data }
		case FETCH_USER_BY_EMAIL_SUCCESS:
			return { ...state, userByEmail: action.data }
		case FETCH_USER_BY_EMAIL_FAILED:
			return { ...state, userByEmail: false }
		case 'SIGN_OUT_REQUESTED':
			return initial
		case FETCH_MFA_STATE_REQUESTED:
			return { ...state, isUserMFAStateLoading: true, isPatientMFAStateLoading: true }
		case UPDATE_MFA_USER_STATE_REQUESTED:
			return { ...state, isUserMFAStateLoading: true }
		case UPDATE_MFA_PATIENT_STATE_REQUESTED:
            return { ...state, isPatientMFAStateLoading: true }
		case FETCH_MFA_STATE_SUCCESS:
        case FETCH_MFA_STATE_FAILED:
		case UPDATE_MFA_STATE_SUCCESS:
            return { ...state, mFAState: action.data, isUserMFAStateLoading: false, isPatientMFAStateLoading: false }
		case UPDATE_MFA_STATE_FAILED:
			return { ...state, isUserMFAStateLoading: false, isPatientMFAStateLoading: false, verifyPhoneMask: null }
		case PHONE_VERIFICATION_CODE_SUCCESS:
			return { ...state, verifyPhoneMask: action.data }
		case RESET_PHONE_VERIFICATION_CODE_REQUESTED:
			return { ...state, verifyPhoneMask: null }
		case 'SET_SETUP_MFA_REQUIRED':
			return { ...state, isSetupMFARequired: action.data }
		case FETCH_USER_HAS_GLOBAL_ACCESS:
			return {
				...state,
				hasGlobalAccess: action.data
			}
		case GET_USER_REGISTRATION_DATA_REQUESTED:
			return { ...state, registrationData: null }
		case GET_USER_REGISTRATION_DATA_SUCCESS:
			return { ...state, registrationData: action.data }
		case GET_IS_USER_REGISTERED_REQUESTED:
			return { 
				...state, 
				isUserRegistered: false,
				isLoading: true
			}
		case GET_IS_USER_REGISTERED_SUCCESS:
			return { 
				...state, 
				isUserRegistered: action.data,
				isLoading: false
			 }
		case GET_IS_USER_REGISTERED_FAILED:
			return { 
				...state, 
				isLoading: false
			}
		case GENERATE_API_KEY_REQUESTED:
			return {
				...state,
				openApiKeyOperationStarted: true
			}
		case GENERATE_API_KEY_SUCCESS:
			return {
				...state,
				openApiKey: action.data
			}
		case GENERATE_API_KEY_FAILED:
			return {
				...state,
				openApiKeyOperationStarted: false
			}
		case CHANGE_API_KEY_STATE_REQUESTED:
			return {
				...state,
				openApiKeyOperationStarted: true
			}
		case DEACTIVATE_API_KEY_SUCCESS:
			return {
				...state,
				openApiKey: null
			}
		case CHANGE_API_KEY_STATE_FAILED:
			return {
				...state,
				openApiKeyOperationStarted: false
			}
		default:
			return { ...state }
	}
}

const mainSelector = (state) => state.user

export const isInvitingSelector = createSelector(mainSelector, (user) => user.isInviting)
export const userDetailsSelector = createSelector(mainSelector, (user) => user.userDetails)
export const userByEmailSelector = createSelector(mainSelector, (user) => user.userByEmail)
export const userNotificationSettingsSelector = createSelector(mainSelector, (user) => user.notificationSettings)
export const userInviteMemberSelector = createSelector(mainSelector, (user) => user.invitation)
export const userInvitationsSelector = createSelector(mainSelector, (user) => {
	return user.userInvitations
		? user.userInvitations.sort((a, b) => {
				return a.isOwner > b.isOwner ? -1 : 1
			})
		: []
})

export const canAddTeamMemberButtonSelector = createSelector(mainSelector, (state) => {
	let user = getUser()

	if (user && state.userDetails && state.userDetails.role) {
		if (state.userDetails.role && state.userDetails.role.id === user.role.id) {
			return true
		}
	}
	return false
})
export const fullUserSelector = createSelector(mainSelector, (state) => (state ? state.fullUser : null))

export const statsSelector = createSelector(mainSelector, (state) => {
	if (state && state.stats) {
		return state.stats
	}
	return false
})

export const teamMembersSelector = createSelector(mainSelector, (state) => {
	return state && state.members ? state.members : []
})

export const isPatientLoadingSelector = createSelector(mainSelector, (state) => {
    return state ? state.isLoading : false;
})

export const emailSelector = createSelector(mainSelector, (user) => user.userDetails && user.userDetails.email)

export const tasksSelector = createSelector(mainSelector, (state) => {
	if (state && state.tasks) {
		return state.tasks
	}
	return false
})

export const sortTaskDirectionSelector = createSelector(mainSelector, (state) => {
	return state.isDescending
})

export const sortTaskFieldSelector = createSelector(mainSelector, (state) => {
	return state.sortField
})

export const verifyPhoneMaskSelector = createSelector(mainSelector, (state) => {
	return state ? state.verifyPhoneMask : null;
})

export const mFAStateSelector = createSelector(mainSelector, (state) => {
	return state ? state.mFAState : null;
})

export const isUserMFAStateLoadingSelector = createSelector(mainSelector, (state) => {
	return state ? state.isUserMFAStateLoading : false;
})

export const isPatientMFAStateLoadingSelector = createSelector(mainSelector, (state) => {
	return state ? state.isPatientMFAStateLoading : false;
})

export const isSetupMFARequiredSelector = createSelector(mainSelector, (state) => {
	return state ? state.isSetupMFARequired : false;
})

export const hasGlobalAccessSelector = createSelector(mainSelector, (state) => {
	return state ? state.hasGlobalAccess : null;
})

export const userRegistrationDataSelector = createSelector(mainSelector, (state) => {
	return state ? state.registrationData : null;
})

export const isUserRegisteredSelector = createSelector(mainSelector, (state) => {
	return state ? state.isUserRegistered : null;
})

export const isLoadingUsersSelector = createSelector(mainSelector, (state) => {
	return state.isLoading
})

export const openApiKeySelector = createSelector(mainSelector, (state) => state?.openApiKey || null)

export const openApiKeyOperationStartedSelector = createSelector(mainSelector, (state) => state?.openApiKeyOperationStarted || null)

export const userJurisdictionSelector = createSelector(mainSelector, (user) => user.userDetails && user.userDetails.teamOwnerJurisdictionId)